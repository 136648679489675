export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const SHOP_COUNTRY_LABELS = {
	"fr-FR": "France",
	"fr-BE": "Belgique",
	"nl-BE": "Belgien",
	"en-GB": "United Kingdom",
	"de-DE": "Deutschland",
	"de-AT": "Österreich",
	"it-IT": "Italia",
	"es-ES": "España",
	"de-CH": "Schweizer",
	"fr-CH": "Suisse",
};

export const BRANDS = {
	XX: "XX",
	AF: "AF",
	VP: "VP",
	EK: "EK",
	HP: "HP",
	PV: "PV",
	VE: "VE",
	DD: "DD",
	MS: "MS",
	VC: "VC",
	TO: "TO",
	CD: "CD",
	LS: "LS",
	SE: "SE",
	UG: "UG",
	GP: "GP",
	TZ: "TZ",
	AX: "AX",
	EH: "EH",
	SP: "SP",
	GE: "GE",
};

export const BRANDS_WITH_DYNAMIC_HEADER = [BRANDS.CD, BRANDS.SP, BRANDS.HP];

export const BRANDS_WITH_LISTING = [BRANDS.SE, BRANDS.TZ, BRANDS.UG];

export const BRANDS_WITH_BREAK = [];

export const PARTNER_CODE_WITH_BREAK = [];

// @see https://github.com/prettymuchbryce/http-status-codes
export const HTTP_STATUS_CODES = {
	OK: 200,
	UNAUTHORIZED: 401,
	BAD_REQUEST: 400,
	FORBIDDEN: 403,
	INTERNAL_SERVER_ERROR: 500,
	GATEWAY_TIMEOUT: 504,
};

export const CIVILITY = {
	MALE: "M",
	FEMALE: "F",
};

export const RESOLUTION = {
	XLARGE: "xlarge",
	LARGE: "large",
	MEDIUM: "medium",
	SMALL: "small",
	UNKNOWN: "unknown",
};

export const CABINS = {
	ECONOMY: "Economy",
	PREMIUM: "Premium Economy",
	BUSINESS: "Business",
	FIRST: "First",
};

export const OFFER_TYPES = {
	ACCOMODATION_ONLY: "AO",
	FLIGHT_WITH_ACCOMMODATION: "FA",
};

export const OFFER_CONTRACT_TYPES = {
	RENTAL: "Rental",
	HOTEL: "Hotel",
};

export const OFFER_PRICE_TYPES = {
	FROM_PRICE_TYPE_SAVE_UP_TO: "FROM_PRICE_TYPE_SAVE_UP_TO",
	FROM_PRICE_TYPE_FROM: "FROM_PRICE_TYPE_FROM",
	FROM_PRICE_TYPE_FROM_WITH_FLIGHT: "FROM_PRICE_TYPE_FROM_WITH_FLIGHT",
	FROM_PRICE_TYPE_EXCLUSIVE: "FROM_PRICE_TYPE_EXCLUSIVE",
};

export const PROMO_CODE_STATUS = {
	REMOVED_PROMOCODE: "REMOVED_PROMOCODE",
	INVALID_PROMOCODE: "INVALID_PROMOCODE",
	SUCCESS: "SUCCESS",
};

export const FLIGHT_OPTIONS_STATUS = {
	ERROR: "ERROR",
	LOADING: "LOADING",
	NO_BAGAGE: "NO_BAGAGE",
	SUCCESS: "SUCCESS",
	SESSION_EXPIRED: "SESSION_EXPIRED",
};

// 500 internal erreur { status: TECHNICAL_FAILURE }
// 400 bad request sans body
export const QUOTATION_CODE_STATUS = {
	FULL_PROPERTY: "FULL_PROPERTY", // plus de dispo hotel 200
	FULL_FLIGHT: "FULL_FLIGHT", // plus de vol 200
	FULL_TRANSFER: "FULL_TRANSFER", // plus de vol 200
	MISSING_INCLUDED_ACTIVITY: "MISSING_INCLUDED_ACTIVITY",
	SUCCESS: "SUCCESS",
	TECHNICAL_FAILURE: "TECHNICAL_FAILURE", // erreur interne erreur 500
	SESSION_EXPIRED: "SESSION_EXPIRED", // devis trop vieux, meme message que actuellement // 200
};

export const NEWSLETTER_FREQUENCIES = {
	NONE: "none",
	WEEKLY: "weekly",
	ALL: "all",
};

export const FLIGHTS_TAG = {
	CHEAPEST: "CHEAPEST",
	CHEAPEST_TRAIN: "CHEAPEST_TRAIN",
	DIRECT_FLIGHT: "DIRECT_FLIGHT",
	BAGGAGE_INCLUDED: "BAGGAGE_INCLUDED",
	CHEAPEST_PRIMARY_AIRPORT: "CHEAPEST_PRIMARY_AIRPORT",
};

export const TRANSPORT_COMPANY_CODES = {
	EUROSTAR: "9F",
	VUELING: "VY",
	RYANAIR: "FR",
	TRANSAVIA_NL: "HV",
	TRANSAVIA: "TO",
};

export const MEDIA_TYPE = {
	VIDEO: "video",
};

export const VIDEO_AUTOPLAY = {
	FALSE: 0,
	TRUE: 1,
};

export const SLIDE_SHOW_PHOTO_TYPES = {
	PHOTOS: "PHOTOS",
	MULTIMEDIA: "MULTIMEDIA",
};

export const MY_BOOKINGS_STATUS = {
	SUCCESS: "SUCCESS",
	TECHNICAL_FAILURE: "TECHNICAL_FAILURE",
	SESSION_EXPIRED: "SESSION_EXPIRED",
	LOADING: "LOADING", // non renvoyé par le serveur, c'est la valeur par défaut du status
};

export const SEARCH_MY_BOOKINGS_STATUS = {
	SUCCESS: "SUCCESS",
	TECHNICAL_FAILURE: "TECHNICAL_FAILURE",
	SESSION_EXPIRED: "SESSION_EXPIRED",
	NOT_FOUND: "NOT_FOUND",
	LOADING: "LOADING", // non renvoyé par le serveur, c'est la valeur par défaut du status
};

export const PRODUCT_PAGE_CODE_STATUS = {
	LOADING: "LOADING",
	SUCCESS: "SUCCESS",
};

export const PROMO_CODE_CODE_STATUS = {
	SUCCESS: "SUCCESS",
	INVALID_PROMOCODE: "INVALID_PROMOCODE", // 200
};

export const PRE_BOOK_CODE_STATUS = {
	SUCCESS: "SUCCESS",
	WARNING: "WARNING",
	FULL_PROPERTY: "FULL_PROPERTY",
	FULL_FLIGHT: "FULL_FLIGHT",
	FULL_TRANSFER: "FULL_TRANSFER",
	TECHNICAL_FAILURE: "TECHNICAL_FAILURE",
	SESSION_EXPIRED: "SESSION_EXPIRED",
};

export const BOOK_STATUS = {
	SUCCESS: "SUCCESS",
	TECHNICAL_FAILURE: "TECHNICAL_FAILURE",
	SESSION_EXPIRED: "SESSION_EXPIRED",
	LOADING: "LOADING",
	WARNING_BOOKING_OK_FULLPAYMENT_FAILED: "WARNING_BOOKING_OK_FULLPAYMENT_FAILED",
	WARNING_BOOKING_FAILED_FULLPAYMENT_FAILED: "WARNING_BOOKING_FAILED_FULLPAYMENT_FAILED",
	WARNING_BOOKING_FAILED_FULLPAYMENT_OK: "WARNING_BOOKING_FAILED_FULLPAYMENT_OK",
	EXTERNAL_REDIRECT_REQUEST: "EXTERNAL_REDIRECT_REQUEST",
	CREDIT_CARD_INFO: "CREDIT_CARD_INFO",
	CREDIT_CARD_TECHNICAL_ERROR: "CREDIT_CARD_TECHNICAL_ERROR",
	CREDIT_CARD_REFUSED: "CREDIT_CARD_REFUSED",
	CREDIT_CARD_3DS_REFUSED: "CREDIT_CARD_3DS_REFUSED",
	WARNING_FULL_PROPERTY: "FULL_PROPERTY",
	EXTERNAL_PAYMENT_STATUS_ERROR: "EXTERNAL_PAYMENT_STATUS_ERROR",
	CHILDREN_AGES_DISCREPANCY: "CHILDREN_AGES_DISCREPANCY",
	INFANTS_AGES_DISCREPANCY: "INFANTS_AGES_DISCREPANCY",
	PAYMENT_AGREEMENT_REFUSED: "PAYMENT_AGREEMENT_REFUSED",
	EXTERNAL_HTML_REQUEST: "EXTERNAL_HTML_REQUEST",
	RETRY: "RETRY",
	DUPLICATE_BOOKING: "DUPLICATE_BOOKING",
};

export const PROFILE_CODE_STATUS = {
	SUCCESS: "SUCCESS",
	FAILURE: "FAILURE",
	LOADING: "LOADING",
};

export const PRODUCTS_VISIBILITY = {
	PREVIEW: "preview",
	CURRENT: "current",
};

// must match formik fields name
export const SDP_PRODUCTS_FILTERS_KEY = {
	STARS: "stars",
	BOARDS: "boards",
	GUESTS_RATING: "guestsrating",
	BUDGET: "budget",
};

export const PRODUCTS_FILTERS_KEY = {
	ZONE: "zone",
	BADGE: "badge",
	DESTINATION: "destination",
	TOP_DESTINATION: "topDestination",
	TOPIC: "topic",
	MONTH: "month",
	PERIOD: "period",
	MERCHANDISINGS: "merchandisings",
	CATEGORY: "category",
	START_AT: "startAt",
	END_AT: "endAt",
	DISCOUNT_PERCENTAGE: "discountPercentage",
};

export const LANDING_PAGE_TYPE = {
	COUNTRIES: "countries",
};

export const COOKIES_AUTH_EXPIRES = 365 * 20;

export const COOKIES_OAUTH_EXPIRES = 1;

export const FLIGHTS_PAGE_SIZE = 3; // nombre de vols max affiché au load dans la page de quotation
export const ACCOMMODATIONS_PAGE_SIZE = 3;
export const QUOTATION_MAX_AGE = 30; // minutes
export const QUOTATION_MAX_ACTIVITIES = 5;
export const QUOTATION_MAX_DEFAULT_ACTIVITIES = 3;

export const SESSION_BIID_EXPIRATION_MAX_AGE = 20;

export const SDP_PRODUCTS_PAGE_SIZE = 20;

export const AUTH_LAYER_SHOW_DELAY = 1000; // ne pas mettre 0 car bug : La frame apparait meme si tu as un uuid dans le localestorage

export const STRATEGY = {
	NAVIGATION_FIRST: "navigationFirst",
	SIGNUP_FIRST: "signupFirst",
	TRANSACTION_FIRST: "transactionFirst",
	OAUTH: "oAuth",
	PRODUCT_FIRST: "productFirst",
	NONE: "none",
};

export const LISTING_DISPLAY_MODE = {
	LIST: "list",
	CARD: "card",
};

// Attention les valeurs ici doivent correspondre aux valeurs Adyen
// Voir https://github.com/Adyen/adyen-cse-web/blob/v0.1.18/js/addOns/adyen.cardtype.js#L107
export const CREDIT_CARDS_TYPES = {
	CB: "cb",
	VISA: "visa",
	VISA_ELECTRON: "visaelectron",
	MASTERCARD: "mc",
	AMEX: "amex",
	MAESTRO: "maestro",
};

export const CB_NUMBER_MAX_LENGTH = 19;

export const DATALAYER_PAYMENT_TYPES = {
	CREDIT_CARD: "CreditCard",
	CHEQUE_VACANCES: "ChequeVacances",
	TRANSFER: "Transfer",
};

export const EXTERNAL_PAYMENT_STATUS = {
	EXTERNAL_PAYMENT_STATUS_ABORTED: "EXTERNAL_PAYMENT_STATUS_ABORTED",
	EXTERNAL_PAYMENT_STATUS_ERROR: "EXTERNAL_PAYMENT_STATUS_ERROR",
	EXTERNAL_PAYMENT_STATUS_PENDING: "EXTERNAL_PAYMENT_STATUS_PENDING",
	EXTERNAL_PAYMENT_STATUS_SUCCESS: "EXTERNAL_PAYMENT_STATUS_SUCCESS",
};

export const EXTERNAL_PAYMENT_HTTP_METHODS = {
	GET: "GET",
};

export const BOOK_EXTERNAL_PAYMENT_STATUS = {
	BOOK_EXTERNAL_PAYMENT_STATUS_UNKNOWN: "BOOK_EXTERNAL_PAYMENT_STATUS_UNKNOWN",
	BOOK_EXTERNAL_PAYMENT_STATUS_ABORTED: "BOOK_EXTERNAL_PAYMENT_STATUS_ABORTED",
	BOOK_EXTERNAL_PAYMENT_STATUS_REFUSED: "BOOK_EXTERNAL_PAYMENT_STATUS_REFUSED",
	BOOK_EXTERNAL_PAYMENT_TECHNICAL_ERROR: "BOOK_EXTERNAL_PAYMENT_TECHNICAL_ERROR",
};

// "France", "Guadeloupe", "Guyane", "Réunion", "Mayotte", "Martinique"
export const FRANCE_DOM_COUNTRIES = ["FR", "GP", "GF", "RE", "YT", "MQ"];

export const IATA_DOM_COUNTRIES = [
	"BBR",
	"DSD",
	"SFG",
	"DSD",
	"LSS",
	"PTP",
	"SBH",
	"SFC",
	"FDF",
	"CAY",
	"MPY",
	"OXP",
	"LDX",
	"REI",
	"XAU",
	"RUN",
	"ZSE",
	"FSP",
	"MQC",
	"DZA",
	"FUT",
	"WLS",
	"AAA",
	"APK",
	"AGR",
	"BOB",
	"FHZ",
	"FAV",
	"FGU",
	"HOI",
	"HHZ",
	"HIX",
	"HUH",
	"KKR",
	"MKP",
	"XMH",
	"MVT",
	"MAU",
	"MOZ",
	"NAU",
	"NHV",
	"NUK",
	"PPT",
	"PKP",
	"PUK",
	"RFP",
	"RGI",
	"REA",
	"RUR",
	"TKP",
	"TKX",
	"TKV",
	"TTI",
	"TIH",
	"TUB",
	"UAH",
	"UAP",
	"VHZ",
	"GMR",
	"TGJ",
	"BMY",
	"KNQ",
	"ILP",
	"KOC",
	"LIF",
	"GEA",
	"PUV",
	"MEE",
	"TOU",
	"UVE",
	"NOU",
];

export const PASSENGER_TYPE = {
	ADULT: "adult",
	INFANT: "infant",
	CHILD: "child",
};

export const PRODUCT_CATEGORY_TYPES = {
	STAR: "Star",
	TRIDENT: "Trident",
};

export const SPONSORSHIP_SOURCES = {
	FRIEND_BOOKED: "friendBooked",
	FRIEND_SUBSCRIBED: "friendSubscribed",
	SPONSORSHIP_ACCEPTED: "sponsorshipAccepted",
};

export const SPONSORSHIP_INVITATION_ERRORS = {
	CANT_SPONSOR_YOURSELF: "CANT_SPONSOR_YOURSELF",
	INVALID_EMAIL_FORMAT: "INVALID_EMAIL_FORMAT",
	ALREADY_FRIEND: "ALREADY_FRIEND",
	ALREADY_MEMBER: "ALREADY_MEMBER",
	EMAIL_PROVIDER_NOT_ACCEPTED: "EMAIL_PROVIDER_NOT_ACCEPTED",
};

export const LISTING_ITEM_TYPES = {
	PRODUCT: "product",
	MERCH_CAROUSSEL: "carousselMerch",
	MERCH_SIMPLE: "simpleMerch",
};

export const PAYMENT_METHODS = {
	CARD: "adyen_cse_card",
	IDEAL: "ideal",
	SOFORT: "sofort",
	GIROPAY: "giropay",
	PAYPAL: "paypal",
	CHEQUE_VACANCES: "cheque",
	CASINO: "casino",
	PLEDG: "pledg",
	BCMC: "bcmc",
	SWISS_BILLING: "swissbilling",
	POSTFINANCE_CARD: "postfinance_card",
	POSTFINANCE_EFINANCE: "postfinance_efinance",
};

export const PAYMENT_OPTIONS = {
	"1x": 1,
	"2x": 2,
	"4x": 4,
};

export const LISTING_ROUTE_PATH = "listing";
export const FLASH_SALE_ROUTE_PATH = "les-ventes-flash";

export const SWISSBILLING_INVOICE_TYPES = {
	EMAIL: "email",
	PAPER: "paper",
};

export const TRANSPORTATION_TYPES = {
	TRAIN: "Train",
	FLIGHT: "Flight",
};
export const CASINO_URL_MENTIONS_LEGALES = "https://floa.fr/mentions-legales";

export const ALERT_TYPE = {
	ERROR: "error",
};

export const QUOTATION_ITEMS_TYPE = {
	TRIP: "TRIP",
	SPECIAL_DISCOUNT: "SPECIAL_DISCOUNT",
	BOOKING_FEES: "BOOKING_FEES",
};

export const QUOTATION_PAYLOAD_PATHS = [
	"offer",
	"departureCity",
	"duration",
	"departureDate",
	"endDate",
	"price",
	"publicPrice",
	"adults",
	"infants",
	"children",
	"config",
	"rentalAccommodation",
	"passengers",
];

export const LOADING_BAR_STATUS = {
	HIDDEN: "HIDDEN",
	LOADING: "LOADING",
	FINISHED: "FINISHED",
};

export const ATOL_PROTECTED_SIZE = {
	BIG: "BIG",
	SMALL: "SMALL",
};

export const UK_ATOL_NUMBER = "11475";

export const BADGE_DEFAULT_COLORS = {
	BORDER: "#000",
	TEXT: "#000",
	BACKGROUND: "#FFF",
};

export const NAVLOGS_SHOW_SLIDES_NUMBER = {
	[RESOLUTION.LARGE]: 3,
	[RESOLUTION.XLARGE]: 3,
	[RESOLUTION.MEDIUM]: 2.3,
	[RESOLUTION.SMALL]: 1.3,
};

export const LOGIN_URL = "/auth/local/login";
export const SIGNUP_URL = "/auth/local/signup";
export const REGISTER_URL = "/auth/local/register";

export const AB_TESTS_STATUS = {
	LOADING: "LOADING" /* Request already sent */,
	SUCCESS: "SUCCESS",
	FAILURE: "FAILURE",
};

export const AB_TESTS_ID_TYPE = {
	PLACEHOLDER: "PLACEHOLDER",
	BACKUP: "BACKUP",
};

export const PRICES_COUNT_THRESHOLD = 7;

// consider using dynamic ruling with Sanity
export const DEPOSIT_ALLOWED_COUNTRIES = ["GB", "IT", "ES"];

export const DEPOSIT_LOT2_COUNTRIES = ["DE", "BE", "AT", "CH"]; //FIXME a supprimer pour le lot 2

export const AB_TESTS = {
	DEPOSIT: {
		CAMPAIGN_ID: "528674",
		VARIATION_ID_DEPOSIT: "659732",
		VARIATION_ID_FULL_PAYMENT: "659733",
	},
};

export const DEPOSIT_PERCENTAGE = 50;
export const DEPOSIT_PERCENTAGE_DE = 50;

// insurance code pattern to detect for the "insurance" "dont want one"
export const NO_INSURANCE_PATTERN = "NO_INSURANCE";

export const PAYMENT_COUPON_TYPE = {
	PROMOCODE: "promocode",
	CREDIT: "credit",
};

export const USABLE_CREDITS_STATUS = {
	SUCCESS: "SUCCESS",
	LOADING_CREDIT_NOTES: "LOADING_CREDIT_NOTES",
	LOADING_COUPONS: "LOADING_COUPONS",
};

export const KEY_CODES = {
	ENTER: 13,
};

export const CREDIT_TYPES = {
	TRAVELBACK: "travelback",
	GENERIC: "generic",
	SPONSORSHIP: "sponsorship",
};

// https://github.com/catamphetamine/libphonenumber-js#gettype
export const LIB_PHONE_NUMBER_TYPES = {
	FIXED_LINE: "FIXED_LINE",
	FIXED_LINE_OR_MOBILE: "FIXED_LINE_OR_MOBILE",
	UNDEFINED: "UNDEFINED",
	MOBILE: "MOBILE",
	PERSONAL_NUMBER: "PERSONAL_NUMBER",
	UAN: "UAN",
	PAGER: "PAGER",
	VOICEMAIL: "VOICEMAIL",
	VOIP: "VOIP",
	SHARED_COST: "SHARED_COST",
	TOLL_FREE: "TOLL_FREE",
	PREMIUM_RATE: "PREMIUM_RATE",
};

export const FREE_CANCELLATION_EN_COUNTRIES = [
	"United Arab Emirates",
	"Maldives",
	"Tanzania",
	"Thailand",
	"Seychelles",
	"Mauritius",
];

export const SPECIAL_OFFER_TYPES = {
	INSURANCE: "INSURANCE",
	REFUNDABLE: "REFUNDABLE",
};

export const NEGOCIATED_TYPES = {
	OTHER: "OTHER",
	ACCOMMODATION: "ACCOMMODATION",
	TRAIN: "TRAIN",
	FLIGHT: "FLIGHT",
	TRANSPORTATION: "TRANSPORTATION",
	TRANSFER: "TRANSFER",
	BOARD: "BOARD",
	EXCURSION: "EXCURSION",
	CAR_RENTAL: "CAR-RENTAL",
	SPECIAL_OFFER: "SPECIAL_OFFER",
};

export const INSURANCE_STANDARD = "STANDARD";

export const SDP_HERO_TEXT_ALIGN = {
	LEFT: "left",
	RIGHT: "right",
	CENTER: "center",
};

export const SDP_SEARCH_MARKETING_BLOCKS = {
	REASONS_TO_BOOK: "reasonsToBook",
	REINSURANCE: "reinsurance",
	IMMERSIVE_BANNER: "immersiveBanner",
	BANNER: "banner",
};

export const STATIC_PAGE_BLOCKS = {
	CENTERED_LOGO_BLOCK: "centeredLogoBlock",
	PHOTO_ARTICLES: "photoArticles",
};

export const UTM_SOURCE = { REMARKETING: "remarketing" };
export const UTM_MEDIUM = { EMAIL_REMARKETING: "email_remarketing" };
