import { combineValidators, isRequired } from "revalidate";
import validationMessages from "app/utils/form/validationMessages";

export const fields = ["bookingReference", "email"];

export const validate = combineValidators({
	bookingReference: isRequired({
		message: validationMessages.bookingReferenceRequired,
	}),
	email: isRequired({
		message: validationMessages.bookingEmailRequired,
	}),
});
