import { getStore } from "app/configureStore";
import { parse, stringify } from "qs";
import {
	extractPartnerDataFromQuery,
	extractPartnerTokensFromQuery,
} from "app/utils/urlDataExtractor";
import isEmpty from "lodash/isEmpty";
import { setPartnerData } from "app/reducers/partnerDataActionCreators";
import { authSuccess, logout, setTokenFromPartner, thunkSetId } from "app/pages/Auth/AuthActions";
import { setSponsorId } from "app/pages/Account/Parrainage/sponsorActionCreators";
import { AUTH_UPDATE_TOKEN, SET_EMAIL, SET_STRATEGY } from "app/actionTypes";
import { updateMarketingData } from "app/reducers/marketingActionCreators";
import { BRANDS } from "app/constants";

export const dispatchOnQueries = ({ location, history }) => {
	if (typeof window !== "undefined") {
		const state = getStore().getState();
		const dispatch = getStore().dispatch;

		const query = parse(location.search, { ignoreQueryPrefix: true });

		const { uuid, partnercode, email, strategy, source, sponsorId, ...restQuery } = query;

		const partnerData = extractPartnerDataFromQuery(state.brand.partnerDataNames, query);
		if (!isEmpty(partnerData)) {
			dispatch(setPartnerData(partnerData));
		}

		const partnerTokens = extractPartnerTokensFromQuery({
			partnerTokenNames: state.brand.partnerTokensNames,
			query,
		});

		if (!isEmpty(partnerTokens)) {
			let token = "";
			const keys = Object.keys(partnerTokens);

			keys.forEach(key => {
				token += token + partnerTokens[key];
			});

			if (token) {
				dispatch(setTokenFromPartner(token));
			}
		}

		if (uuid || email || strategy || source || sponsorId) {
			if (uuid) {
				dispatch(thunkSetId(uuid));
			}

			if (sponsorId) {
				dispatch(setSponsorId(sponsorId));
			}

			if (email) {
				// Si l'email dans l'url diffère de celui récupéré du localstorage
				if (state.email && state.email !== email) {
					// ... alors on déconnecte l'utilisateur pour éviter qu'une réservation se fasse avec un mauvais email
					dispatch(logout(partnercode));
				} else {
					dispatch({
						type: SET_EMAIL,
						email: email,
					});
				}
			}

			if (strategy) {
				dispatch({
					type: SET_STRATEGY,
					strategy,
				});
			}

			if (source) {
				dispatch(updateMarketingData({ source }));
			}

			const stringifyQuery = stringify({
				...restQuery,
			});

			history.replace(`${location.pathname}?${stringifyQuery}`);
		}
	}
};

export const redirectOnOperatorLogin = ({ location, history }) => {
	const query = parseQueries(location.search);

	const dispatch = getStore().dispatch;

	const { uuid, email, token, partner, redirectUrl, strategy } = query;

	// Pour les brands ayant une stack membre, on authentifie le user
	if (uuid && email && token && partner) {
		dispatch(
			authSuccess({
				data: {
					uuid,
					email,
					token,
					partner: { code: partner },
				},
			})
		);
	} else {
		dispatch({
			type: AUTH_UPDATE_TOKEN,
			token: token,
		});
	}

	if (strategy) {
		dispatch({
			type: SET_STRATEGY,
			strategy,
		});
	}

	const pathname = redirectUrl && !redirectUrl.includes("://") ? redirectUrl : "/listing";

	history.replace(pathname);
};

export const redirectOnMerch = ({ brand, history, location }) => {
	const query = parse(location.search, { ignoreQueryPrefix: true });

	if (location.pathname === "/merch") {
		if (query.code === "PARRAINAGE") {
			if (typeof window !== "undefined") {
				history.replace({ pathname: "/account/sponsorship" });
			}
		} else if (query.code === "TRAVELDOR") {
			if (typeof window !== "undefined") {
				if (brand === BRANDS.AF) {
					window.location.replace(
						"https://jeu.traveldor.travel/agences-de-voyages/471-la-collection-air-france"
					);
				} else if (brand === BRANDS.EK) {
					window.location.replace(
						"https://jeu.traveldor.travel/agences-de-voyages/554-emirates-the-list"
					);
				}
			}
		}
	}
};

export const parseQueries = search => {
	return parse(search, { ignoreQueryPrefix: true }) || {};
};

export const getParentPath = (url, keyword) => {
	if (keyword) {
		return url.slice(0, url.indexOf(keyword) - 1);
	}
	return url.slice(0, url.lastIndexOf("/"));
};
