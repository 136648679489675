import {
	CHECK_AVAILABILITIES_REQUEST,
	CHECK_AVAILABILITIES_SUCCESS,
	CLEAR_QUOTATION,
	FETCH_FLIGHT_OPTIONS_FAILURE,
	FETCH_FLIGHT_OPTIONS_REQUEST,
	FETCH_FLIGHT_OPTIONS_SUCCESS,
	FETCH_PRODUCTS_REQUEST,
	PRE_BOOK_REQUEST,
	PRE_BOOK_SUCCESS,
	RESET_FLIGHTS_FILTER,
	RESET_FLIGHTS_PAGE_SIZE,
	SET_FLIGHTS_FILTER_BY,
	SET_FLIGHTS_SORT_BY,
	SHOW_FLEX_RATES_MODAL,
	SHOW_MORE_FLIGHTS,
} from "app/actionTypes";
import find from "lodash/find";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { actionTypes as reduxActionTypes } from "redux-form";
import {
	ACCOMMODATIONS_PAGE_SIZE,
	FLIGHT_OPTIONS_STATUS,
	FLIGHTS_PAGE_SIZE,
	PRE_BOOK_CODE_STATUS,
	QUOTATION_CODE_STATUS,
} from "app/constants";
import {
	SHOW_ALL_ACTIVITIES,
	SHOW_MORE_ACCOMMODATIONS,
} from "app/pages/Booking/Quotation/quotationActionTypes";
import {
	CHECK_SDP_AVAILABILITIES_REQUEST,
	CHECK_SDP_AVAILABILITIES_SUCCESS,
	SAVE_SELECTED_SDP_PRODUCT,
} from "app/pages/SmartDP/smartDPActionTypes";

export const initialQuotation = {
	timestamp: undefined,
	status: "LOADING",
	preBookStatus: undefined,
	flightOptionsStatus: undefined,
	code: undefined,
	accommodations: [],
	roomDescriptions: [],
	boards: [],
	activities: [],
	flights: [],
	insurances: [],
	transfers: [],
	quotationItems: [],
	sortFlightsBy: "price",
	filterFlightsBy: {
		stop: undefined,
		inboundDuration: undefined,
		outboundDuration: undefined,
		inboundTakeOffTime: {
			min: undefined,
			max: undefined,
		},
		outboundTakeOffTime: {
			min: undefined,
			max: undefined,
		},
		departureAirport: undefined,
		arrivalAirport: undefined,
		airline: undefined,
		luggage: undefined,
		upgradePrice: undefined,
		cabin: undefined,
	},
	flightsPageSize: FLIGHTS_PAGE_SIZE,
	accommodationsPageSize: ACCOMMODATIONS_PAGE_SIZE,
	showAllActivities: false,
	deltaBasePrice: 0,
	taggedFlightsNumber: FLIGHTS_PAGE_SIZE,
	specialOffer: undefined,
	isFlexRatesModalOpened: false,
};

export default (quotation = initialQuotation, action) => {
	switch (action.type) {
		case reduxActionTypes.CHANGE: {
			// Si FULL_FLIGHT apres un pre book, on affiche un message erreur au dessus du bloc de vol
			// On supprime le message d'erreur si la personne sélectionne un autre vol

			const nextState = { ...quotation };

			if (
				action.form === "booking-quotation-form" &&
				action.field === "flight" &&
				quotation.preBookStatus === PRE_BOOK_CODE_STATUS.FULL_FLIGHT
			) {
				nextState.preBookStatus = undefined;
			}

			return nextState;
		}
		case FETCH_PRODUCTS_REQUEST:
		case CLEAR_QUOTATION:
		case CHECK_SDP_AVAILABILITIES_REQUEST:
		case CHECK_AVAILABILITIES_REQUEST:
			return initialQuotation;
		case CHECK_SDP_AVAILABILITIES_SUCCESS:
		case CHECK_AVAILABILITIES_SUCCESS: {
			const data = action.res.data;
			if (data.status === QUOTATION_CODE_STATUS.SUCCESS) {
				const taggedFlights =
					(data.flights && data.flights.filter(flight => flight.tag)) || [];

				return {
					...quotation,
					timestamp: Date.now(),
					status: data.status,
					code: data.code,
					activities: data.activities || [],
					insurances: data.insurances || [],
					accommodations: get(data, "accommodations.accommodationItems", []), // can be undefined for smartdp
					roomDescriptions: get(data, "accommodations.accommodationItemDescriptions", []), // can be undefined for smartdp
					boards: get(data, "accommodations.boards", []), // can be undefined for smartdp
					flights: data.flights || [],
					quotationItems: data.quotationItems,
					accommodationsPageSize: ACCOMMODATIONS_PAGE_SIZE,
					taggedFlightsNumber: taggedFlights.length,
					flightsPageSize: taggedFlights.length,
					specialOffer: data.specialOffer || undefined,
				};
			}
			return {
				...quotation,
				status: data.status,
				code: data.code,
				activities: [],
				insurances: [],
				accommodations: [],
				roomDescriptions: [],
				boards: [],
				flights: [],
				quotationItems: [],
				specialOffer: undefined,
			};
		}
		case SAVE_SELECTED_SDP_PRODUCT: {
			const accommodation = action.accommodation;
			return {
				...quotation,
				accommodations: accommodation.accommodationItems || [],
				roomDescriptions: accommodation.accommodationItemDescriptions || [],
				boards: accommodation.boards || [],
			};
		}
		case SET_FLIGHTS_SORT_BY:
			return { ...quotation, sortFlightsBy: action.sortBy };
		case SET_FLIGHTS_FILTER_BY:
			return {
				...quotation,
				filterFlightsBy: { ...quotation.filterFlightsBy, ...action.filterBy },
			};
		case SHOW_MORE_FLIGHTS:
			return { ...quotation, flightsPageSize: quotation.flightsPageSize + FLIGHTS_PAGE_SIZE };
		case SHOW_ALL_ACTIVITIES:
			return { ...quotation, showAllActivities: true };
		case RESET_FLIGHTS_FILTER: {
			const filtersValues = action.filtersValues;
			return {
				...quotation,
				flightsPageSize: quotation.taggedFlightsNumber,
				filterFlightsBy: {
					...quotation.filterFlightsBy,
					stop: undefined,
					departureAirport: undefined,
					arrivalAirport: undefined,
					airline: undefined,
					luggage: undefined,
					upgradePrice: filtersValues.upgradePrice,
					inboundDuration: filtersValues.inboundDuration.max,
					outboundDuration: filtersValues.outboundDuration.max,
					inboundTakeOffTime: {
						min: filtersValues.inboundTakeOffTime.min,
						max: filtersValues.inboundTakeOffTime.max,
					},
					outboundTakeOffTime: {
						min: filtersValues.outboundTakeOffTime.min,
						max: filtersValues.outboundTakeOffTime.max,
					},
				},
			};
		}
		case PRE_BOOK_REQUEST: {
			return { ...quotation, deltaBasePrice: 0 };
		}
		case PRE_BOOK_SUCCESS: {
			const data = action.res.data;
			const status = data.status;
			let newState = cloneDeep(quotation);

			newState.preBookStatus = data.status;

			if (status === PRE_BOOK_CODE_STATUS.WARNING) {
				newState.deltaBasePrice = data.priceChange.newPrice - data.priceChange.oldPrice;
			}

			return newState;
		}
		case FETCH_FLIGHT_OPTIONS_REQUEST:
			return { ...quotation, flightOptionsStatus: FLIGHT_OPTIONS_STATUS.LOADING };
		case FETCH_FLIGHT_OPTIONS_FAILURE:
			return { ...quotation, flightOptionsStatus: FLIGHT_OPTIONS_STATUS.ERROR };
		case FETCH_FLIGHT_OPTIONS_SUCCESS: {
			const data = action.res.data;

			if (
				data.status === FLIGHT_OPTIONS_STATUS.SUCCESS ||
				data.status === FLIGHT_OPTIONS_STATUS.NO_BAGAGE
			) {
				// on fait une copy de flights pour avoir une nouvelle reference et
				// mettre à jour le quotation.flights dans l'appli sinon reselect renvoie le même array
				const copyOfFlights = [...quotation.flights];

				const flightCode = data.flight;

				const flight = find(copyOfFlights, flight => {
					return flight.code === flightCode;
				});

				flight.flightOptions.flightBagageOptions = data.flightOptions.flightBagageOptions;
				flight.flightOptions.flightBagageOption = data.flightOptions.flightBagageOption;
				flight.transfers = data.transfers;

				return {
					...quotation,
					flightOptionsStatus: data.status,
					flights: copyOfFlights,
				};
			}

			return { ...quotation, flightOptionsStatus: data.status };
		}
		case SHOW_MORE_ACCOMMODATIONS:
			return {
				...quotation,
				accommodationsPageSize: quotation.accommodationsPageSize + ACCOMMODATIONS_PAGE_SIZE,
			};
		case RESET_FLIGHTS_PAGE_SIZE:
			return {
				...quotation,
				flightsPageSize: quotation.taggedFlightsNumber,
			};
		case SHOW_FLEX_RATES_MODAL:
			return { ...quotation, isFlexRatesModalOpened: action.isFlexRatesModalOpened };

		default:
			return quotation;
	}
};
