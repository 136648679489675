import React from "react";
import PropTypes from "prop-types";
import HomeMarketing from "@brand/Home/desktop/HomeMarketing";
import "./AuthLayerMarketingText.scss";

const AuthLayerMarketingText = ({ image }) => {
	return (
		<div className="auth-layer-marketing-text">
			<HomeMarketing image={image} />
		</div>
	);
};

AuthLayerMarketingText.propTypes = {
	image: PropTypes.string,
};

export default React.memo(AuthLayerMarketingText);
