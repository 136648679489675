import React from "react";
import "./SimpleHeader.scss";
import { sendTagOnContactCall } from "app/utils/analytics";
import PropTypes from "prop-types";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import { brandPropTypes } from "app/utils/propTypes";
import { BRANDS } from "app/constants";
import IconPhone from "app/pages/.shared/static/icons/IconPhoneBordered";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";

const SimpleHeader = ({
	previousUrl,
	dialPhone,
	hideBackButton = false,
	brand,
	operatorEmail,
	isInApp,
	forceBrowserBack,
}) => {
	return isInApp ? (
		false
	) : (
		<div className="simple-header">
			<div className="simple-header__back">
				{!hideBackButton && (
					<BackButton
						to={{
							pathname: previousUrl,
						}}
						forceBrowserBack={forceBrowserBack}
						label="general.back"
					/>
				)}
			</div>
			<div className="simple-header__title">
				<LogoBrandContainer
					isWhite={
						brand === BRANDS.EK ||
						brand === BRANDS.HP ||
						brand === BRANDS.GP ||
						brand === BRANDS.SE ||
						brand === BRANDS.TZ
					}
				/>
			</div>
			{operatorEmail && (
				<div className="simple-header__operator">
					<strong>Agent :</strong> {operatorEmail}
				</div>
			)}
			<div className="simple-header__phone">
				{dialPhone && (
					<a
						className="simple-header__phone-button"
						onClick={() => {
							sendTagOnContactCall();
						}}
						href={`tel:${dialPhone}`}
					>
						<IconPhone height={30} width={30} />
					</a>
				)}
			</div>
		</div>
	);
};

SimpleHeader.propTypes = {
	brand: brandPropTypes,
	dialPhone: PropTypes.string,
	previousUrl: PropTypes.string,
	hideBackButton: PropTypes.bool,
	operatorEmail: PropTypes.string,
	isInApp: PropTypes.bool,
	forceBrowserBack: PropTypes.bool,
};

export default React.memo(SimpleHeader);
