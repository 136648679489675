import React from "react";
import { FormattedMessage } from "react-intl";
import RelativeLink from "app/pages/.shared/RelativeLink";

const HelpLink = () => (
	<RelativeLink rel="noopener noreferrer" target="_blank" to={{ pathname: "/faq" }}>
		<FormattedMessage id="menu.item.help" />
	</RelativeLink>
);

export default React.memo(HelpLink);
