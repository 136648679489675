import { createSelector } from "reselect";
import get from "lodash/get";
import uniq from "lodash/uniq";
import flatten from "lodash/flatten";

const getPartner = state => state.partner;

export const getPartnerCode = state => state.partner && state.partner.code;

export const getFallbackRedirectUrl = createSelector(
	[getPartner],
	(partner = {}) => {
		return partner.fallbackUrl;
	}
);

export const shouldUseBrowserBackOnProductPage = createSelector(
	[getPartner],
	(partner = {}) => {
		return Boolean(partner.useBrowserBackOnProductPage);
	}
);

export const getBrandDisplayName = createSelector(
	[getPartner],
	(partner = {}) => {
		return partner.brandDisplayName || "";
	}
);

export const getPartnerMarketing = createSelector(
	[getPartner],
	partner => {
		return get(partner, "marketing", {});
	}
);

export const getAvailablePaymentTerms = createSelector(
	[getPartner],
	partner => {
		const paymentMethods = partner.availablePaymentMethods || [];

		const paymentTerms = paymentMethods.map(paymentMethod => {
			return paymentMethod.termsChoices;
		});

		return uniq(flatten(paymentTerms));
	}
);

export const getMetaTags = createSelector(
	[getPartner],
	partner => {
		return get(partner, "metaTags", []);
	}
);

export const getTheme = createSelector(
	[getPartner],
	partner => {
		return get(partner, "theme", {});
	}
);

export const getFooterData = createSelector(
	[getPartner],
	partner => {
		return get(partner, "footer", {});
	}
);

export const getRobotsMetaTag = createSelector(
	[getPartner],
	(partner = {}) => {
		return {
			default: "no index, no follow",
			listing: "no index, no follow",
			product: "no index, no follow",
			...partner.robotsMetaTag,
		};
	}
);

export const getHomeImages = createSelector(
	[getPartner],
	(partner = {}) => {
		return partner.home;
	}
);
