import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import env from "app/utils/env";
import { bindActionCreators } from "redux";
import { setFacebookReady } from "app/pages/Auth/Facebook/facebookActionCreators";

const FACEBOOK_API_VERSION = "v6.0";

export default Component => {
	const WaitForFacebook = props => {
		const { shop, isFacebookSDKReady, setFacebookReady } = props;

		useEffect(() => {
			const FACEBOOK_APP_ID = env("FACEBOOK_APP_ID");

			if (!isFacebookSDKReady && FACEBOOK_APP_ID) {
				const language = shop.replace("-", "_");

				// Code below is given by facebook deocumentation.
				// Paste from the doc and update appId, version and language
				window.fbAsyncInit = function() {
					FB.init({
						appId: FACEBOOK_APP_ID,
						version: FACEBOOK_API_VERSION,
						status: true,
					});

					setFacebookReady();
				};

				(function(d, s, id) {
					var js,
						fjs = d.getElementsByTagName(s)[0];
					if (d.getElementById(id)) {
						return;
					}
					js = d.createElement(s);
					js.id = id;
					js.src = `https://connect.facebook.net/${language}/sdk.js`;
					fjs.parentNode.insertBefore(js, fjs);
				})(document, "script", "facebook-jssdk");
				// code above is given by facebook deocumentation
			}
		}, [shop, isFacebookSDKReady]);

		return <Component {...props} />;
	};

	WaitForFacebook.defaultProps = {
		shop: "",
		isFacebookSDKReady: false,
	};

	WaitForFacebook.propTypes = {
		shop: PropTypes.string,
		isFacebookSDKReady: PropTypes.bool,
		setFacebookReady: PropTypes.func,
	};

	const mapStateToProps = state => {
		return {
			shop: state.shop,
			isFacebookSDKReady: state.externalStatus.facebookSDKReady,
		};
	};

	const mapDispatchToProps = dispatch => {
		return bindActionCreators({ setFacebookReady }, dispatch);
	};

	return connect(
		mapStateToProps,
		mapDispatchToProps
	)(WaitForFacebook);
};
