import React from "react";
import { connect } from "react-redux";
import { getBrandDisplayName } from "app/reducers/partnerSelector";
import AuthLayer from "app/pages/Auth/AuthLayer/AuthLayer";

const AuthLayerContainer = props => {
	return <AuthLayer {...props} />;
};

const mapStateToProps = state => {
	return {
		brandDisplayName: getBrandDisplayName(state),
	};
};

export default connect(mapStateToProps)(AuthLayerContainer);
