import React from "react";
import PropTypes from "prop-types";
import reduxFormSubmitter from "app/utils/form/reduxFormSubmitter";
import { fields, validate } from "app/pages/CorporateSite/SearchBooking/SearchBookingFormSchema";
import validationMessages from "app/utils/form/validationMessages";
import SearchBookingForm from "app/pages/CorporateSite/SearchBooking/SearchBookingForm";
import { initialize, stopSubmit, untouch } from "redux-form";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import { sendTagOnBookingSearch } from "app/utils/analytics";
import { withRouter } from "react-router-dom";
import {
	authSuccess,
	authSuccessForShop,
	loginWithBookingReference,
} from "app/pages/Auth/AuthActions";
import { parseQueries } from "app/utils/routerUtils";

class SearchBookingFormContainer extends React.PureComponent {
	componentDidMount() {
		// pre renseigner le champ email
		this.props.initializeForm(this.props.initializeValues);
	}

	render() {
		return <SearchBookingForm {...this.props} />;
	}
}

SearchBookingFormContainer.propTypes = {
	initializeForm: PropTypes.func,
	initializeValues: PropTypes.object,
};

const mapFormToPayload = values => {
	return {
		password: values.bookingReference,
		email: values.email,
	};
};

const getFormGlobalError = errors => {
	let _error = validationMessages.formGenericError;
	if (errors.bookingReference) {
		_error = errors.bookingReference;
	} else if (errors.email) {
		_error = errors.email;
	}
	return _error;
};

const beforeSubmit = values => {
	sendTagOnBookingSearch({
		password: values.bookingReference,
		email: values.email,
	});
};

// use by darksite brand XX
// authenticate the user for a specific shop
// use case : user is authenticated for the shop of the booking that is different of the current shop before being
// redirected to the url with the shop of the booking.
const onSubmitSuccess = (response, ownProps, dispatch, values, state) => {
	dispatch(authSuccess(response));

	const query = parseQueries(ownProps.location.search);
	let pathname = "/account/mybookings";

	if (query.onauthsuccess) {
		pathname = query.onauthsuccess;
	}

	const currentShop = state.shop;
	const shop = get(response, "data.partner.shop");

	if (shop && currentShop !== shop) {
		const partnerCode = get(response, "data.partner.code");
		const token = get(response, "data.token");
		const uuid = get(response, "data.uuid");
		const email = get(response, "data.email");

		authSuccessForShop({ shop, partnerCode, token, uuid, email });

		window.location = `${window.origin}/${shop}${pathname}`;
	} else {
		dispatch(authSuccess(response));

		if (query.onauthsuccess) {
			// we don't want the user to go to the auth page on back
			ownProps.history.replace({
				pathname,
			});
		} else {
			ownProps.history.push({
				pathname,
			});
		}
	}
};

const onSubmitFail = (response, ownProps, reject) => {
	let _error = validationMessages.generic;

	if (response.status === 404) {
		_error = validationMessages.bookingNotFound;
	}

	reject({ _error });
};

const mapStateToProps = state => {
	return {
		initializeValues: {
			email: state.email,
		},
	};
};

const mapDispatchToProps = dispatch => {
	return Object.assign(
		{},
		{
			initializeForm: initializeValues => {
				dispatch(initialize("searchBooking-form", initializeValues));
			},
			resetFormError: () => {
				dispatch(untouch("searchBooking-form", "bookingReference", "email"));
				// Supprimer les erreurs formulaires https://github.com/erikras/redux-form/issues/2604#issuecomment-282670843
				dispatch(stopSubmit("searchBooking-form", {}));
			},
		},
		bindActionCreators({ untouch }, dispatch)
	);
};

const reduxFormSubmitterConfig = {
	form: "searchBooking-form",
	fields,
	validateForm: validate,
	mapFormToPayload,
	actionCreator: loginWithBookingReference,
	getFormGlobalError,
	beforeSubmit,
	onSubmitSuccess,
	onSubmitFail,
};

export default withRouter(
	reduxFormSubmitter(reduxFormSubmitterConfig, mapStateToProps, mapDispatchToProps)(
		SearchBookingFormContainer
	)
);
