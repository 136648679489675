import "./patchReact";
import React from "react";
import ReactDOM from "react-dom";
import { Route, Router } from "react-router-dom";
import getHistory from "app/configureHistory";
import { loadableReady } from "@loadable/component";
import { Provider } from "react-redux";
import { configureStore } from "../app/configureStore";
import { shouldPolyfill as shouldPolyfillPluralRules } from "@formatjs/intl-pluralrules/should-polyfill";
import { shouldPolyfill as shouldPolyfillCanonicalLocales } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { initPersistor } from "app/utils/persistor";
import ResponsiveStateProvider from "app/pages/.shared/responsive/ResponsiveStateProvider.jsx";
import { IntlProvider } from "react-intl";
import env from "app/utils/env";
import PerfectAppContainer from "app/PerfectAppContainer";
import { fetchMessages } from "src/intl/intlUtils";
import { parse } from "qs";
import { sendTagOnException, virtualPageLoading } from "app/utils/analytics";
import { fetchABTestsActiveCampaignsRequest } from "app/reducers/abTestsActionCreators";
import { initializeSentry } from "app/utils/sentry";
import ScrollToTop from "app/pages/.shared/Scroll/ScrollToTop";
import * as Sentry from "@sentry/react";
import SimpleHeaderContainer from "app/pages/Header/SimpleHeader/SimpleHeaderContainer";
import TechnicalErrorDefault from "app/pages/TechnicalError/default/TechnicalErrorDefault";
import FooterContainer from "app/pages/Footer/FooterContainer";
import handleError from "app/utils/errorHandler";

//scoped references
const initialState = window.__INITIAL_STATE__ || {};
const { shop, brand, envVars } = initialState;
const locale = shop.slice(0, 2);
const store = configureStore(initialState);

initializeSentry(store.getState());

let intl;

// On migre toutes les données du localstorage dans la shop par défaut fr-FR pour ne pas être considéré comme déconnecté
// lors du passage vers secu-af
if (window.localStorage) {
	const authData = window.localStorage.getItem("reduxPersist:auth");
	if (authData) {
		window.localStorage.setItem("reduxPersist:fr-FR:auth", authData);
		window.localStorage.removeItem("reduxPersist:auth");
	}

	const emailData = window.localStorage.getItem("reduxPersist:email");
	if (emailData) {
		window.localStorage.setItem("reduxPersist:fr-FR:email", emailData);
		window.localStorage.removeItem("reduxPersist:email");
	}

	const cookiePolicyData = window.localStorage.getItem("reduxPersist:isCookiePolicyAccepted");
	if (cookiePolicyData) {
		window.localStorage.setItem("reduxPersist:fr-FR:isCookiePolicyAccepted", cookiePolicyData);
		window.localStorage.removeItem("reduxPersist:isCookiePolicyAccepted");
	}

	const partnerData = window.localStorage.getItem("reduxPersist:partner");
	if (partnerData) {
		window.localStorage.setItem("reduxPersist:fr-FR:partner", partnerData);
		window.localStorage.removeItem("reduxPersist:partner");
	}
}

/*
 ** async execution sequence
 */

const asyncIntlPolyfill = locale => {
	const polyfills = [];

	if (shouldPolyfillCanonicalLocales()) {
		polyfills.push([import("@formatjs/intl-getcanonicallocales/polyfill")]);
	}

	if (shouldPolyfillPluralRules()) {
		polyfills.push([import("@formatjs/intl-pluralrules/polyfill")]);

		switch (locale) {
			default:
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/en"));
				break;
			case "fr":
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/fr"));
				break;
			case "es":
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/es"));
				break;
			case "nl":
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/nl"));
				break;
			case "it":
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/it"));
				break;
			case "de":
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/de"));
				break;
		}
	}

	if (polyfills.length === 0) {
		return Promise.resolve();
	}

	return Promise.all(polyfills);
};

asyncIntlPolyfill(locale).then(() => {
	const apiURL = env("CONTENT_API_URL") || env("BASE_URL");

	fetchMessages(apiURL, brand.code, shop).then(messages => {
		// Note : Seulement cote front (voir i18nMiddleware pour la generation serveur)

		intl = {
			messages: messages,
			locale: shop,
		};
		asyncStoreLoader();
	});
});

function asyncStoreLoader() {
	initPersistor(store, { shop }, renderApp);
}

function renderApp() {
	const injectAnalyticScripts = () => {
		let gtmId = envVars.GTM_ID;

		if (gtmId) {
			const GTM_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${gtmId}');`;

			const script = document.createElement("script");
			script.text = GTM_SCRIPT;
			const head = document.getElementsByTagName("head")[0];
			head.appendChild(script);
		}
	};

	const checkEnvironnementVariables = () => {
		const { BASE_URL, QUOTE_API_URL, ADYEN_API_KEY } = envVars;

		if (!BASE_URL) {
			throw new Error("Missing environment variable : BASE_URL");
		}

		if (!QUOTE_API_URL) {
			throw new Error("Missing environment variable : QUOTE_API_URL");
		}

		if (!ADYEN_API_KEY) {
			throw new Error("Missing environment variable : ADYEN_API_KEY");
		}
	};

	checkEnvironnementVariables();

	const app = (
		<Provider store={store}>
			<ResponsiveStateProvider>
				<IntlProvider {...intl}>
					<Router history={getHistory(shop)}>
						<ScrollToTop />
						<Route
							path="/"
							render={({ location }) => {
								const query = parse(location.search, { ignoreQueryPrefix: true });

								// virtualPageLoading à envoyer à chaque changement d'url mais également au premier chargement de la page
								virtualPageLoading({
									productId: location.state && location.state.productId,
									pageTitle: location.pathname,
									landingPageTopic: query.topic,
									shop,
								});

								/* abTests initialization */
								store.dispatch(fetchABTestsActiveCampaignsRequest());

								return (
									<Sentry.ErrorBoundary
										onError={error => {
											handleError({ error });
											sendTagOnException({ error });
										}}
										fallback={
											<React.Fragment>
												<SimpleHeaderContainer />
												<div style={{ margin: "40px 0" }}>
													<TechnicalErrorDefault />
												</div>
												<FooterContainer />
											</React.Fragment>
										}
									>
										<PerfectAppContainer />
									</Sentry.ErrorBoundary>
								);
							}}
						/>
					</Router>
				</IntlProvider>
			</ResponsiveStateProvider>
		</Provider>
	);

	loadableReady(() => {
		ReactDOM.hydrate(app, document.querySelector("#perfectstay"), () => {
			// timeout pour s'assurer que les photos chargent bien après le chat et le script Google Analytics
			// eslint-disable-next-line max-nested-callbacks
			setTimeout(() => {
				injectAnalyticScripts();
			}, 500);
		});
	});
}

if (module.hot) {
	module.hot.accept("app/PerfectAppContainer", () => {
		renderApp();
	});
}
