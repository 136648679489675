import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import HomeBackground from "@brand/Home/desktop/HomeBackground.jsx";
import "./AuthModal.scss";
import IconAtolProtected from "app/pages/.shared/static/icons/IconAtolProtected";
import IconABTACertification from "app/pages/.shared/BookWithConfidence/IconABTACertification";
import AuthLayerContainer from "app/pages/Auth/AuthLayer/AuthLayerContainer";
import AuthLayerMarketingText from "app/pages/Auth/AuthLayerMarketingText/AuthLayerMarketingText";

const style = {
	overlay: {
		backgroundColor: "rgba(43, 56, 63, .9)",
		zIndex: 6,
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		border: "none",
		background: "none",
		overflow: "hidden",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};
const IS_DEFAULT_SIGN_IN = false;
const AuthModal = ({ onAuthSuccess, showAtolAbta, home = {}, ...props }) => {
	return (
		<Modal style={style} {...props}>
			<div className="auth-modal">
				<div className="auth-modal__hero-image">
					<HomeBackground
						image={home.imageDesktop}
						thumbnail={home.imageDesktopThumbnail}
					/>
				</div>

				<div className="auth-modal__auth-layer">
					<AuthLayerContainer
						isDefaultSignin={IS_DEFAULT_SIGN_IN}
						onAuthSuccess={onAuthSuccess}
						signinMarketingBlock={<AuthLayerMarketingText image={home.textDesktop} />}
						signupMarketingBlock={<AuthLayerMarketingText image={home.textDesktop} />}
					/>
				</div>

				{showAtolAbta && (
					<div className="auth-modal__certifications">
						<IconAtolProtected height={40} width={40} />
						<IconABTACertification height={40} width={70} noColor={true} />
					</div>
				)}
			</div>
		</Modal>
	);
};

AuthModal.propTypes = {
	onAuthSuccess: PropTypes.func.isRequired,
	showAtolAbta: PropTypes.bool,
	home: PropTypes.object,
};
export default AuthModal;
