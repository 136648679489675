import { FETCH_FIRST_PRODUCTS_SUCCESS, FETCH_PRODUCTS_SUCCESS } from "app/actionTypes";
import {
	FETCH_MERCHANDISING_SUCCESS,
	SET_MERCH_PRODUCTS_FILTER_BY,
	UNSET_MERCH_PRODUCTS_FILTER_BY,
	UPDATE_CURRENT_MERCH_CODE,
	UPDATE_MERCH_PREVIEW_START_DATE,
} from "app/pages/Merchandising/merchandisingActionTypes";
import { getDuration } from "app/utils/utils";

const initialState = {
	current: {},
	products: [],
	list: [],
	activeFilter: undefined,
	previewStartDate: undefined,
};

export default (merchandising = initialState, action) => {
	switch (action.type) {
		case FETCH_FIRST_PRODUCTS_SUCCESS:
		case FETCH_PRODUCTS_SUCCESS: {
			const merchandisings = action.res.data.merchandisings;

			return {
				...initialState,
				list: merchandisings || [],
				activeFilter: undefined,
				previewStartDate: merchandising.previewStartDate,
			};
		}
		case UPDATE_MERCH_PREVIEW_START_DATE: {
			return {
				...merchandising,
				previewStartDate: action.previewStartDate,
			};
		}
		case FETCH_MERCHANDISING_SUCCESS: {
			const now = new Date();

			const {
				merchandising: merchandisingData = {},
				merchandisings = [],
				products = [],
			} = action.res.data;

			const newProductList = products.map(product => {
				if (product.endAt) {
					product.expiration = getDuration(now.getTime(), product.endAt);
				}

				if (product.startAt) {
					product.availabilityDate = getDuration(now.getTime(), product.startAt);
				}
				return product;
			});

			return {
				loading: false,
				current: merchandisingData,
				list: merchandising.list, // liste des merchs avec images et tous les infos, destinés uniquement pour la page listing
				lightList: merchandisings, // liste des merchs avec le minimum de data, uniquement pour la page merchandising pour le composant merch switcher
				products: newProductList,
				activeFilter: undefined,
				previewStartDate: merchandising.previewStartDate,
			};
		}
		case SET_MERCH_PRODUCTS_FILTER_BY: {
			return { ...merchandising, activeFilter: action.filterBy };
		}
		case UNSET_MERCH_PRODUCTS_FILTER_BY: {
			return { ...merchandising, activeFilter: undefined };
		}
		case UPDATE_CURRENT_MERCH_CODE: {
			return {
				...merchandising,
				current: { code: action.code },
			};
		}
		default:
			return merchandising;
	}
};
