import React from "react";
import IconEngagementVoyageException from "app/pages/.shared/static/icons/IconEngagementVoyagesException";
import { FormattedMessage } from "react-intl";
import IconEngagementEconomies from "app/pages/.shared/static/icons/IconEngagementEconomies";
import IconEngagementExpertsEcoute from "app/pages/.shared/static/icons/IconEngagementExpertsEcoute";
import IconEngagementPaiementSecurise from "app/pages/.shared/static/icons/IconEngagementPaiementSecurise";
import "./Reassurance.scss";
import ReassurancePayment from "app/pages/.shared/ReassurancePayment/ReassurancePayment";
import PropTypes from "prop-types";

const Reassurance = ({ paymentMethods }) => {
	return (
		<ul className="reassurance">
			<li className="reassurance__item">
				<IconEngagementVoyageException width={35} height={35} />
				<div className="reassurance__label">
					<FormattedMessage id="general.engagement.1" />
				</div>
			</li>
			<li className="reassurance__item">
				<IconEngagementEconomies width={35} height={35} />
				<div className="reassurance__label">
					<FormattedMessage id="general.engagement.2" />
				</div>
			</li>
			<li className="reassurance__item">
				<IconEngagementExpertsEcoute width={35} height={35} />
				<div className="reassurance__label">
					<FormattedMessage id="general.engagement.3" />
				</div>
			</li>
			<li className="reassurance__item">
				<IconEngagementPaiementSecurise width={35} height={35} />
				<div className="reassurance__label">
					<FormattedMessage id="general.engagement.4" />
				</div>
				<ReassurancePayment paymentMethods={paymentMethods} />
			</li>
		</ul>
	);
};

Reassurance.propTypes = {
	paymentMethods: PropTypes.array,
};

Reassurance.defaultProps = {
	paymentMethods: [],
};

export default React.memo(Reassurance);
