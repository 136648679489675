import PropTypes from "prop-types";
import React from "react";
import { initialize, propTypes, reduxForm, stopSubmit, touch, untouch } from "redux-form";
import { fields } from "./SigninSchema";
import { FormattedMessage, injectIntl } from "react-intl";
import Input from "app/pages/.shared/form/Input";
import Popover from "app/pages/.shared/Popover";
import Button from "app/pages/.shared/form/Button";
import RelativeLink from "app/pages/.shared/RelativeLink";
import FacebookContainer from "app/pages/Auth/Facebook/FacebookContainer.jsx";
import { anyFieldTouched } from "app/utils/form-validator";
import "../auth.scss";
import { withRouter } from "react-router-dom";
import { getParentPath } from "app/utils/routerUtils";

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.onFacebookError = this.onFacebookError.bind(this);
		this.onErrorClose = this.onErrorClose.bind(this);
	}

	componentDidMount() {
		this.props.initializeForm(this.props.initializeValues);
	}

	onErrorClose() {
		this.props.resetFormError();
	}

	onFacebookError() {
		this.props.stopSubmission();
	}

	render() {
		const {
			fields,
			handleSubmit,
			submitting,
			error,
			showTopFacebook = true,
			showBottomFacebook = false,
			intl,
			headline = <FormattedMessage id="auth.signin.headline" />,
			footer = null,
			facebookOnSuccess,
			onToogleSignupForm,
			location,
			homeRedirection = true,
		} = this.props;

		const parentPath = getParentPath(location.pathname) || "/home";

		return (
			<div className="auth">
				<div className="auth__main">
					<div className="auth__switch">
						<div
							className="auth__switch__item"
							onClick={onToogleSignupForm}
							data-cy="switch-to-signUp"
						>
							<FormattedMessage id="home.signup.button.label" />
						</div>
						<div
							className="auth__switch__item auth__switch__item--active"
							data-cy="switch-to-login"
						>
							<FormattedMessage id="home.signin.button.label" />
						</div>
					</div>

					{headline && <header className="auth__headline">{headline}</header>}

					{showTopFacebook && (
						<div>
							<div className="auth__facebook">
								<FacebookContainer
									onSuccess={facebookOnSuccess}
									onError={this.onFacebookError}
								/>
							</div>

							<div className="auth__separator">
								<FormattedMessage id="general.ou" />
							</div>
						</div>
					)}

					<form noValidate className="auth__form" onSubmit={handleSubmit}>
						<Input
							type="email"
							field={fields.email}
							label={<FormattedMessage id="auth.email.label" />}
							name="auth-email"
							id="auth-email"
							data-cy="email-input"
						/>

						<Input
							type="password"
							field={fields.password}
							label={<FormattedMessage id="auth.password.label" />}
							name="auth-password"
							id="auth-password"
							data-cy="password-input"
						/>

						<div className="auth__forgotten-password">
							<RelativeLink
								to={{
									pathname: `${
										homeRedirection ? "/home" : parentPath
									}/resetpassword`,
								}}
								data-cy="forgot-password-button"
							>
								<FormattedMessage id="auth.password.forgotten.label" />
							</RelativeLink>
						</div>

						<div className="auth__action">
							<Button
								className="auth__button"
								submit
								design="primary"
								loading={submitting}
								data-cy="login-button"
							>
								<FormattedMessage id="auth.signin.button.label" />
							</Button>
							<Popover
								onClose={this.onErrorClose}
								isOpen={anyFieldTouched(fields) && error}
							>
								{/* TODO passer a la version > 0.4.5 dereact-popover pour pouvoir utiliser un FormattedMessage au lieu de intl  */}
								{error ? intl.formatMessage(error) : ""}
							</Popover>

							{showBottomFacebook && (
								<div>
									<div className="auth__separator">
										<FormattedMessage id="general.ou" />
									</div>
									<div className="auth__facebook">
										<FacebookContainer
											onSuccess={facebookOnSuccess}
											onError={this.onFacebookError}
										/>
									</div>
								</div>
							)}
						</div>
					</form>
					{footer && <div className="auth__footer">{footer}</div>}
				</div>
			</div>
		);
	}
}

Login.propTypes = {
	...propTypes,
	intl: PropTypes.object,
	headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	facebookOnSuccess: PropTypes.func,
	onToogleSignupForm: PropTypes.func,
	showTopFacebook: PropTypes.bool,
	showBottomFacebook: PropTypes.bool,
};

const mapStateToProps = state => {
	return {
		initializeValues: {
			email: state.email,
		},
	};
};

const mapDispatchToProps = dispatch => {
	return {
		initializeForm: initializeValues => {
			dispatch(initialize("login-form", initializeValues));
		},
		resetFormError: () => {
			dispatch(untouch("login-form", "email", "password"));
			// Supprimer les erreurs formulaires https://github.com/erikras/redux-form/issues/2604#issuecomment-282670843
			dispatch(stopSubmit("login-form", {}));
		},
		stopSubmission: () => {
			dispatch(touch("login-form", "email", "password")); // pour faire apparaitre l'erreur
			dispatch(
				stopSubmit("login-form", {
					_error: {
						id: "error.facebook.no.email",
					},
				})
			);
		},
	};
};

const reduxFormConfig = {
	form: "login-form",
	fields,
};

export default withRouter(
	injectIntl(reduxForm(reduxFormConfig, mapStateToProps, mapDispatchToProps)(Login))
);
