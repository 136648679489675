import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { authSuccess, logout } from "app/pages/Auth/AuthActions";
import submitter from "app/utils/redux-submitter";
import env from "app/utils/env";
import FacebookLogin from "app/pages/Auth/Facebook/FacebookLogin";
import { getStore } from "app/configureStore";
import { bindActionCreators } from "redux";
import { setFacebookReady } from "app/pages/Auth/Facebook/facebookActionCreators";
import {
	sendTagOnFacebookError,
	sendTagOnFacebookSignin,
	sendTagOnFacebookSignup,
} from "app/utils/analytics";
import waitForFacebookApi from "app/utils/waitForFacebookApi";

const FACEBOOK_CONNECT_URL = "/auth/facebook";
const FACEBOOK_STATUS = {
	CONNECTED: "connected",
	NOT_AUTHORIZED: "not_authorized",
	UNKNOWN: "unknown",
};

const FacebookContainer = ({ isFacebookSDKReady, facebookLogin, signup }) => {
	const [isLoading, setLoading] = useState(false);

	const reAuthorizeFacebook = useCallback(() => {
		window.FB.login(
			response => {
				setLoading(false);
				facebookLogin(response.authResponse);
			},
			{ auth_type: "reauthorize" }
		);
	});

	const loginWithFacebook = useCallback(() => {
		window.FB.login(
			response => {
				setLoading(false);
				if (response.authResponse) {
					facebookLogin(response.authResponse);
				} else {
					facebookLogin({ status: response.status });
				}
			},
			{
				scope: "public_profile,email",
			}
		);
	});

	const handleFacebookAuth = useCallback(() => {
		setLoading(true);

		window.FB.getLoginStatus(function(response) {
			const authResponse = response && response.authResponse;

			if (response.status === FACEBOOK_STATUS.CONNECTED) {
				const facebookDataAccessExpiration =
					authResponse.data_access_expiration_time * 1000;
				const today = Date.now();

				const isDataAccessExpired = today > facebookDataAccessExpiration;

				if (isDataAccessExpired) {
					reAuthorizeFacebook();
				} else {
					setLoading(false);
					facebookLogin(response.authResponse);
				}
			} else if (response.status === FACEBOOK_STATUS.NOT_AUTHORIZED) {
				loginWithFacebook();
			} else {
				loginWithFacebook();
			}
		});
	});

	return (
		<FacebookLogin
			isSignup={signup}
			isLoading={isLoading}
			onClick={handleFacebookAuth}
			isDisabled={!isFacebookSDKReady}
		/>
	);
};

FacebookContainer.propTypes = {
	facebookLogin: PropTypes.func,
	isFacebookSDKReady: PropTypes.bool,
	onSuccess: PropTypes.func,
	onError: PropTypes.func,
	signup: PropTypes.bool,
};

FacebookContainer.defaultProps = {
	signup: false,
};

const promiseOnData = data => {
	const store = getStore().getState();

	return axios.post(
		env("USER_AUTH_API_URL") + FACEBOOK_CONNECT_URL,
		{
			...data,
			partner: store.partner,
			source: store.marketing.source,
		},
		{
			headers: {
				"Content-type": "text/plain",
			},
		}
	);
};

const mapStateToProps = state => {
	return {
		isFacebookSDKReady: state.externalStatus.facebookSDKReady,
	};
};

const mapDispatchToProps = (dispatch, { onSuccess, onError, ...props }) => {
	return Object.assign(
		{},
		{
			facebookLogin: data =>
				submitter(
					promiseOnData,
					success => {
						dispatch(logout);
						dispatch(authSuccess(success));
						if (success.data.signup) {
							sendTagOnFacebookSignup();
						} else {
							sendTagOnFacebookSignin();
						}

						if (onSuccess && typeof onSuccess === "function") {
							onSuccess(success);
						}
					},
					error => {
						sendTagOnFacebookError(error.data.message);

						if (onError && typeof onError === "function") {
							onError(error);
						}
					}
				)(data, dispatch, props),
		},
		bindActionCreators({ setFacebookReady }, dispatch)
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(waitForFacebookApi(FacebookContainer));
