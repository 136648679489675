import {
	CHECK_SDP_AVAILABILITIES_SUCCESS,
	FETCH_SDP_FILTERS_SUCCESS,
	FETCH_SDP_QUOTE_REQUEST,
	FETCH_SDP_QUOTE_SUCCESS,
	SAVE_SDP_SEARCH_QUERY_STRING,
	SHOW_MORE_SDP_PRODUCTS,
} from "app/pages/SmartDP/smartDPActionTypes";
import { QUOTATION_CODE_STATUS, SDP_PRODUCTS_PAGE_SIZE } from "app/constants";

export const initialStaState = {
	status: "LOADING",
	accommodationsList: [],
	searchQueryString: "",
	accommodationsPageSize: SDP_PRODUCTS_PAGE_SIZE,
	destinations: [],
	departureCities: [],
	departureDateMin: undefined,
	departureDateMax: undefined,
	cabins: [],
	code: undefined,
	flights: [],
	specialOffer: undefined,
	specialOfferInfos: undefined,
	timestamp: undefined,
};

export default (dpQuote = initialStaState, action) => {
	switch (action.type) {
		case FETCH_SDP_QUOTE_REQUEST: {
			return {
				...dpQuote,
				code: undefined,
				flights: [],
				status: "LOADING",
				specialOffer: undefined,
				specialOfferInfos: undefined,
				accommodationsList: [],
				quotationItems: [],
				accommodationsPageSize: SDP_PRODUCTS_PAGE_SIZE,
				timestamp: undefined,
			};
		}
		case SHOW_MORE_SDP_PRODUCTS:
			return {
				...dpQuote,
				accommodationsPageSize: dpQuote.accommodationsPageSize + SDP_PRODUCTS_PAGE_SIZE,
			};
		case SAVE_SDP_SEARCH_QUERY_STRING: {
			return {
				...dpQuote,
				searchQueryString: action.search,
			};
		}
		case FETCH_SDP_QUOTE_SUCCESS: {
			const data = action.res.data || {};

			return {
				...dpQuote,
				status: data.status,
				code: data.code,
				accommodationsList: data.accommodationsList,
				flights: data.flights || [],
				quotationItems: data.quotationItems || [],
				specialOffer: data.specialOffer,
				specialOfferInfos: data.specialOfferInfos,
				timestamp: new Date().getTime(),
			};
		}
		case FETCH_SDP_FILTERS_SUCCESS: {
			const filters = action.res.data;
			return {
				...dpQuote,
				departureCities: filters.departureCities,
				destinations: filters.destinations,
				cabins: filters.cabins,
				departureDateMin: filters.departureDateMin,
				departureDateMax: filters.departureDateMax,
			};
		}
		case CHECK_SDP_AVAILABILITIES_SUCCESS: {
			const status = action.res.data.status;

			if (status === QUOTATION_CODE_STATUS.SESSION_EXPIRED) {
				return {
					...dpQuote,
					code: undefined,
					flights: [],
					status: "LOADING",
					specialOffer: undefined,
					specialOfferInfos: undefined,
					accommodationsList: [],
					quotationItems: [],
					accommodationsPageSize: SDP_PRODUCTS_PAGE_SIZE,
					timestamp: undefined,
					searchQueryString: "",
				};
			}
			return dpQuote;
		}
		default:
			return dpQuote;
	}
};
