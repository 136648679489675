import {
	CHECK_AVAILABILITIES_REQUEST,
	CHECK_AVAILABILITIES_SUCCESS,
	CLEAR_BOOKING,
	FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_FAILURE,
	FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_SUCCESS,
	FETCH_FICHE_PRODUIT_SUCCESS,
	FETCH_FLIGHT_OPTIONS_REQUEST,
	FETCH_FLIGHT_OPTIONS_SUCCESS,
	FETCH_PRODUCTS_SUCCESS,
	FETCH_PROFILE_REQUEST,
	PRE_BOOK_REQUEST,
	PRE_BOOK_SUCCESS,
	SEND_PROMOTION_CODE_SUCCESS,
	UPDATE_BOOKING,
	UPDATE_BOOKING_ACTIVITY,
	UPDATE_BOOKING_ACTIVITY_DATE,
	UPDATE_BOOKING_CHILDREN_BIRTHDATES,
	UPDATE_BOOKING_INFANTS_BIRTHDATES,
	UPDATE_BOOKING_PASSENGER,
} from "app/actionTypes";
import {
	AB_TESTS,
	OFFER_TYPES,
	PASSENGER_TYPE,
	PAYMENT_COUPON_TYPE,
	PAYMENT_METHODS,
	PAYMENT_OPTIONS,
	QUOTATION_CODE_STATUS,
	QUOTATION_PAYLOAD_PATHS,
} from "app/constants";

import {
	HYDRATE_QUOTATION_PAYLOAD,
	SAVE_QUOTATION_PAYLOAD,
} from "app/pages/Booking/bookingActionTypes";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import set from "lodash/set";
import remove from "lodash/remove";
import { getStore } from "app/configureStore";
import { FETCH_USABLE_COUPONS_SUCCESS } from "app/pages/Account/MyCoupons/couponActionTypes";
import { calculateUsableCreditsTotal } from "app/pages/Account/MyCoupons/couponSelector";
import {
	FETCH_SDP_QUOTE_SUCCESS,
	FETCH_SDP_QUOTE_REQUEST,
	SAVE_SELECTED_SDP_PRODUCT,
	CHECK_SDP_AVAILABILITIES_SUCCESS,
} from "app/pages/SmartDP/smartDPActionTypes";

export const INITIAL_BOOKING = {
	offer: {},
	departureCity: {},
	departureDate: undefined,
	endDate: undefined,
	price: undefined,
	publicPrice: undefined,
	adults: 2,
	children: 0,
	infants: 0,
	duration: {},
	transfer: {},
	hasSpecialOffer: true,
	accommodation: {},
	rentalAccommodation: {},
	board: {},
	credits: 0,
	flight: {},
	luggage: 0,
	activities: [],
	coupon: undefined,
	paymentType: "adyen_cse_card",
	insurance: {},
	// on souhaite persister les données saisies si on revient sur la page paiement avant d'avoir la confirmation de réservation
	// exemple : reload, annulation depuis un partenaire externe et redirection sur la page de paiement, erreur de book
	contact: undefined,
	paymentMode: undefined, // TODO remettre la valeur a 1 apres l'ab test deposit
	passengers: [
		{
			type: PASSENGER_TYPE.ADULT,
			index: 1,
		},
		{
			type: PASSENGER_TYPE.ADULT,
			index: 2,
		},
	],
	quotationPayloads: [],
	insuranceFlex: undefined,
	occupancies: [{ adults: 2, children: 0, childrenBirthdates: [] }], // SDP
	cabin: {}, // SDP
	destinationResort: {}, // SDP
};

export default (booking = INITIAL_BOOKING, action) => {
	switch (action.type) {
		case HYDRATE_QUOTATION_PAYLOAD: {
			let hydratedBooking = booking.quotationPayloads
				? booking.quotationPayloads.find(
						quotationPayload => quotationPayload.productUri === action.productUri
				  )
				: {};
			QUOTATION_PAYLOAD_PATHS.forEach(path => {
				if (isEqual(get(booking, path), get(INITIAL_BOOKING, path))) {
					set(
						hydratedBooking,
						path,
						get(hydratedBooking, path, get(INITIAL_BOOKING.path))
					);
				} else {
					set(hydratedBooking, path, get(booking, path));
				}
			});
			return {
				...booking,
				...hydratedBooking,
			};
		}
		case SAVE_QUOTATION_PAYLOAD: {
			const localStorageQuotationPayloads = JSON.parse(
				localStorage.getItem(`reduxPersist:${action.shop}:booking`)
			);

			const quotationPayloads = get(localStorageQuotationPayloads, "quotationPayloads")
				? get(localStorageQuotationPayloads, "quotationPayloads").filter(
						quotationPayload => quotationPayload.productUri !== action.product.uri
				  )
				: [];

			const QUOTATION_PAYLOADS_MAX_LENGTH = 500;
			const quotationPayloadToSave = QUOTATION_PAYLOAD_PATHS.reduce(
				(acc, path) => set(acc, path, get(action.quotationpayload, path)),
				{
					productUri: action.product.uri,
					isChildrenBirthdateRequired: action.product.isChildrenBirthdateRequired,
				}
			);
			return {
				...booking,
				quotationPayloads: [
					...(quotationPayloads.length > QUOTATION_PAYLOADS_MAX_LENGTH
						? quotationPayloads.slice(1)
						: quotationPayloads),
					quotationPayloadToSave,
				],
			};
		}
		case FETCH_FICHE_PRODUIT_SUCCESS: {
			// Si on a qu'une seule offre, alors on la préselectionne par défaut
			const offers = get(action, "res.data.offers");
			const offer = offers[0];
			const offerType = offer.pricing.type;
			let offerToSave = {};
			let configToSave = undefined;

			if (offerType === OFFER_TYPES.ACCOMODATION_ONLY) {
				const { type, label, contractType, uri, config } = offer.pricing;
				offerToSave = { type, label, contractType, uri, negotiated: offer.negotiated };
				configToSave = config;
			} else if (offerType === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION) {
				const { type, label, contractType, config } = offer.pricing;
				offerToSave = { type, label, contractType, negotiated: offer.negotiated };
				configToSave = config;
			}

			if (offers.length === 1) {
				return { ...booking, config: configToSave, offer: offerToSave };
			}
			return booking;
		}
		case FETCH_PRODUCTS_SUCCESS:
		case CLEAR_BOOKING:
			return { ...INITIAL_BOOKING, quotationPayloads: booking.quotationPayloads };
		case UPDATE_BOOKING_PASSENGER: {
			const passengers = booking.passengers.filter(passenger => {
				return passenger.type !== action.passengerType;
			});

			const matchedPassengers = booking.passengers.filter(passenger => {
				return passenger.type === action.passengerType;
			});

			for (let i = 0; i < action.count; i++) {
				passengers.push(
					Object.assign({}, matchedPassengers[i], {
						index: i + 1,
						type: action.passengerType,
					})
				);
			}

			const bookingPart = {
				passengers,
			};

			if (action.passengerType === PASSENGER_TYPE.ADULT) {
				bookingPart.adults = action.count;
			} else if (action.passengerType === PASSENGER_TYPE.CHILD) {
				bookingPart.children = action.count;
			} else if (action.passengerType === PASSENGER_TYPE.INFANT) {
				bookingPart.infants = action.count;
			}

			return Object.assign({}, booking, {
				...bookingPart,
			});
		}
		case UPDATE_BOOKING_CHILDREN_BIRTHDATES: {
			const passengers = booking.passengers.concat([]);
			const child = passengers.find(
				passenger =>
					passenger.type === PASSENGER_TYPE.CHILD && passenger.index === action.index + 1
			);

			child.birthdate = action.birthdate;

			return Object.assign({}, booking, {
				passengers,
			});
		}
		case FETCH_PROFILE_REQUEST: {
			return {
				...booking,
				contact: undefined,
			};
		}
		case UPDATE_BOOKING_INFANTS_BIRTHDATES: {
			const passengers = booking.passengers.concat([]);
			const infant = passengers.find(
				passenger =>
					passenger.type === PASSENGER_TYPE.INFANT && passenger.index === action.index + 1
			);
			infant.birthdate = action.birthdate;

			return Object.assign({}, booking, {
				passengers,
			});
		}
		case SAVE_SELECTED_SDP_PRODUCT: {
			return {
				...booking,
				accommodation: {},
				board: {},
			};
		}
		case FETCH_SDP_QUOTE_REQUEST:
		case CHECK_AVAILABILITIES_REQUEST:
			// TODO composer les reducers : avoir un reducer pour booking quotation
			return Object.assign({}, booking, {
				accommodation: {},
				board: {},
				flight: {},
				insurance: {},
				paymentType: "adyen_cse_card",
				paymentMode: undefined, // TODO remettre la valeur a 1 apres l'ab test deposit
				credits: 0,
				coupon: undefined,
				transfer: {},
				activities: [],
				insuranceFlex: undefined,
			});
		case UPDATE_BOOKING:
			return Object.assign({}, booking, action.booking);
		case FETCH_FLIGHT_OPTIONS_REQUEST: {
			// on reinitialise la sélection du transfer lorsque l'on recupère les transferts du vol selectionné
			return {
				...booking,
				transfer: undefined,
			};
		}
		case FETCH_FLIGHT_OPTIONS_SUCCESS: {
			const data = action.res.data;
			if (booking.flight.code === data.flight) {
				return {
					...booking,
					flight: {
						...booking.flight,
						flightOptions: {
							...booking.flight.flightOptions,
							...data.flightOptions,
						},
						transfers: data.transfers,
					},
				};
			}
			return booking;
		}
		case CHECK_SDP_AVAILABILITIES_SUCCESS:
		case CHECK_AVAILABILITIES_SUCCESS: {
			const data = action.res.data;

			const selectedActivities = [...booking.activities];

			if (data.status === QUOTATION_CODE_STATUS.SUCCESS) {
				const quoteActivities = data.activities || [];

				// Pour chaque activité, on regarde si le nombre de participants inclus pour la date suggérée est > 0
				// Si oui, alors on ajoute l'activité dans la liste des activités sélectionnées avec le nombre de chaque participant
				// initialisé au nombre de participants inclus

				quoteActivities.forEach(quoteActivity => {
					const suggestedDate = quoteActivity.suggestedDate;

					const guestsOfSuggestedDate = quoteActivity.guestsPerDay[suggestedDate] || [];

					const includedGuest = guestsOfSuggestedDate.find(guestOfSuggestedDate => {
						return guestOfSuggestedDate.includedGuest > 0;
					});

					if (includedGuest) {
						const guests = [];

						guestsOfSuggestedDate.forEach(guestOfSuggestedDate => {
							const isGuestIncluded = guestOfSuggestedDate.includedGuest > 0;

							if (isGuestIncluded) {
								guests.push({
									code: guestOfSuggestedDate.code,
									guestsCount: guestOfSuggestedDate.includedGuest,
								});
							}
						});

						const selectedActivity = selectedActivities.find(
							activity => activity.code === quoteActivity.code
						);

						if (selectedActivity) {
							selectedActivity.guests = guests;
							selectedActivity.date = suggestedDate;
						} else {
							selectedActivities.push({
								code: quoteActivity.code,
								name: quoteActivity.name,
								date: suggestedDate,
								guests: guests,
							});
						}
					}
				});
			}

			return {
				...booking,
				activities: selectedActivities,
			};
		}
		case UPDATE_BOOKING_ACTIVITY: {
			const code = action.activity.code;
			const activityName = action.activity.name;
			const guestsCount = action.activity.guest.guestsCount;
			const guestCode = action.activity.guest.code;

			const activities = booking.activities.concat([]);

			const activitytoUpdate = activities.find(activity => {
				return activity.code === code;
			});

			if (activitytoUpdate) {
				const guestToUpdate = activitytoUpdate.guests.find(guest => {
					return guest.code === guestCode;
				});

				if (guestToUpdate) {
					guestToUpdate.guestsCount = guestsCount;
				} else {
					activitytoUpdate.guests.push({
						code: guestCode,
						guestsCount,
					});
				}

				const activitytoUpdateGuestcount = activitytoUpdate.guests.reduce(
					(acc, { guestsCount }) => {
						return acc + guestsCount;
					},
					0
				);

				if (activitytoUpdateGuestcount === 0) {
					remove(activities, activityToRemove => {
						return activityToRemove.code === activitytoUpdate.code;
					});
				}
			} else {
				activities.push({
					code: code,
					name: activityName,
					guests: [
						{
							code: guestCode,
							guestsCount,
						},
					],
				});
			}

			return { ...booking, activities };
		}

		case UPDATE_BOOKING_ACTIVITY_DATE: {
			const { code, date } = action.payload;

			const selectedActivities = [...booking.activities];

			const activitytoUpdate = selectedActivities.find(activity => {
				return activity.code === code;
			});

			if (activitytoUpdate) {
				activitytoUpdate.date = date;
			}
			return { ...booking, activities: selectedActivities };
		}
		case PRE_BOOK_REQUEST: {
			return {
				...booking,
			};
		}
		// TODO supprimer le case PRE_BOOK_SUCCESS après l'ab test Deposit
		case PRE_BOOK_SUCCESS: {
			const state = getStore().getState();
			const activeCampaigns = get(state, "abTests.activeCampaigns", []);
			const activeDepositCampaign = activeCampaigns.find(
				campaign => campaign.campaignID === AB_TESTS.DEPOSIT.CAMPAIGN_ID
			);

			if (!activeDepositCampaign) {
				return booking;
			}

			const paymentTypes = action.res.data.paymentTypes || [];

			const paymentDeposit = paymentTypes.find(
				paymentType =>
					paymentType.terms === PAYMENT_OPTIONS["2x"] &&
					paymentType.paymentType === PAYMENT_METHODS.CARD
			);

			if (!booking.paymentMode) {
				const activeVariation = activeDepositCampaign && activeDepositCampaign.variationID;

				let abTestPaymentMode =
					activeVariation === AB_TESTS.DEPOSIT.VARIATION_ID_DEPOSIT
						? PAYMENT_OPTIONS["2x"]
						: PAYMENT_OPTIONS["1x"];

				if (!paymentDeposit) {
					abTestPaymentMode = PAYMENT_OPTIONS["1x"];
				}

				return {
					...booking,
					paymentMode: abTestPaymentMode,
				};
			}

			return booking;
		}
		// TODO supprimer le case FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_SUCCESS après l'ab test Deposit
		case FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_SUCCESS: {
			const state = getStore().getState();
			const paymentTypes = get(state, "payment.paymentTypes", []);

			// si en récupérant la variation, on a pas la liste des moyens de payment, on selectionne par défaut le full payment
			// if (paymentTypes.length === 0) {
			// 	return {
			// 		...booking,
			// 		paymentMode: PAYMENT_OPTIONS["1x"],
			// 	};
			// }

			if (!booking.paymentMode && paymentTypes.length !== 0) {
				const paymentDeposit = paymentTypes.find(
					paymentType =>
						paymentType.terms === PAYMENT_OPTIONS["2x"] &&
						paymentType.paymentType === PAYMENT_METHODS.CARD
				);

				const activeCampaigns = action.activeCampaigns || [];
				const activeDepositCampaign = activeCampaigns.find(
					campaign => campaign.campaignID === AB_TESTS.DEPOSIT.CAMPAIGN_ID
				);

				const activeVariation = activeDepositCampaign && activeDepositCampaign.variationID;

				let abTestPaymentMode =
					activeVariation === AB_TESTS.DEPOSIT.VARIATION_ID_DEPOSIT
						? PAYMENT_OPTIONS["2x"]
						: PAYMENT_OPTIONS["1x"];

				if (activeVariation === AB_TESTS.DEPOSIT.VARIATION_ID_DEPOSIT && !paymentDeposit) {
					abTestPaymentMode = PAYMENT_OPTIONS["1x"];
				}

				if (!activeDepositCampaign) {
					abTestPaymentMode = PAYMENT_OPTIONS["1x"];
				}

				return {
					...booking,
					paymentMode: abTestPaymentMode,
				};
			}

			return booking;
		}
		case FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_FAILURE: {
			// TODO supprimer le case FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_FAILURE après l'ab test Deposit
			if (!booking.paymentMode) {
				return {
					...booking,
					paymentMode: PAYMENT_OPTIONS["1x"],
				};
			}

			return booking;
		}
		case SEND_PROMOTION_CODE_SUCCESS: // when only promotioncode is enabled, coupon should be set to PROMOCODE
		case FETCH_USABLE_COUPONS_SUCCESS: {
			const credit = get(action, "res.data.credit", {});
			const totalUsableCredit = calculateUsableCreditsTotal(
				get(credit, "generic.usable", []),
				get(credit, "travelback.usable", []),
				get(credit, "sponsorship.usable", [])
			);

			if (!booking.coupon) {
				return {
					...booking,
					...{
						coupon:
							totalUsableCredit > 0
								? PAYMENT_COUPON_TYPE.CREDIT
								: PAYMENT_COUPON_TYPE.PROMOCODE,
					},
				};
			}

			return booking;
		}
		case FETCH_SDP_QUOTE_SUCCESS: {
			// On marque le vol inclus retourné par la sdp/quote comme le vol sélectionné afin que les dates du sdp search summary soit valorisé avec les bonnes infos de dates
			const flights = get(action, "res.data.flights", []);
			const includedFlight = flights.find(flight => flight.included);

			return {
				...booking,
				flight: includedFlight,
				offer: {
					type: OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION,
				},
				// equivalent de i.so au niveau de chaque date de depart du moteur de recherche
				hasSpecialOffer: get(action, "res.data.specialOffer"),
			};
		}
		default:
			return booking;
	}
};
