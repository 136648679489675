import PropTypes from "prop-types";
import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { untouch } from "redux-form";
import submitter from "app/utils/redux-submitter";
import Login from "app/pages/Auth/Signin/Login.jsx";
import { authSuccess, logout } from "app/pages/Auth/AuthActions";
import { validate } from "./SigninSchema";
import validationMessages from "app/utils/form/validationMessages.js";
import isEmpty from "lodash/isEmpty";
import { sendTagOnEmailSignin, sendTagOnEmailSigninError } from "app/utils/analytics";
import env from "app/utils/env";
import { acceptCookyPolicy } from "app/pages/.shared/CookiePolicyFooter/cookiePolicyActionCreators";
import { getParentPath } from "app/utils/routerUtils";
import { BRANDS } from "app/constants";
import { brandPropTypes } from "app/utils/propTypes";

const LOGIN_URL = "/auth/local/login";

const promiseOnData = data => {
	let _error;
	const errors = validate(data);
	if (!isEmpty(errors)) {
		_error = errors[Object.keys(errors)[0]];
		return Promise.reject({ _error });
	}

	return axios.post(
		LOGIN_URL,
		{ credentials: data },
		{
			baseURL: env("USER_AUTH_API_URL"),
			headers: {
				"Content-type": "text/plain",
			},
		}
	);
};

const onError = error => {
	if (Object.prototype.hasOwnProperty.call(error, "_error")) {
		sendTagOnEmailSigninError({ status: "", error: error._error.id });
		return error;
	}

	let _error = validationMessages.generic;

	if (error.status === 404) {
		_error = validationMessages.emailNotRegistered;
	} else if (error.status === 403) {
		_error = validationMessages.facebookAccountWithoutPassword;
	} else if (error.status === 401) {
		_error = validationMessages.passwordIncorrect;
	}

	sendTagOnEmailSigninError({ status: error.status, error: _error.id });

	return { _error };
};

const LoginContainer = ({
	headline,
	footer,
	showTopFacebook,
	showBottomFacebook,
	onAuthSuccess,
	onToogleSignupForm,
	untouch,
	history,
	acceptCookyPolicy,
	location,
	homeRedirection = true,
	brand,
	logout,
}) => {
	const onToogleSignup =
		onToogleSignupForm && typeof onToogleSignupForm === "function"
			? onToogleSignupForm
			: () => {
					const parentPath = getParentPath(location.pathname);
					history.push({ pathname: `${parentPath}/signup` });
			  };

	const enableFacebookLogin = brand !== BRANDS.AX;

	return (
		<Login
			homeRedirection={homeRedirection}
			showBottomFacebook={enableFacebookLogin && showBottomFacebook}
			showTopFacebook={enableFacebookLogin && showTopFacebook}
			headline={headline}
			footer={footer}
			onSubmit={submitter(
				promiseOnData,
				(success, dispatch) => {
					logout();
					dispatch(authSuccess(success));
					sendTagOnEmailSignin();
					acceptCookyPolicy();
					if (onAuthSuccess && typeof onAuthSuccess === "function") {
						onAuthSuccess(success, dispatch);
					} else {
						history.replace("/listing"); // TODO pour le cas /home/signup continue de fonctionner. A refactorer en utilisant authSuccess
					}
				},
				onError
			)}
			facebookOnSuccess={(success, dispatch) => {
				// TODO refactorer avec onSuccess
				acceptCookyPolicy();
				if (onAuthSuccess && typeof onAuthSuccess === "function") {
					onAuthSuccess(success, dispatch);
				} else {
					history.replace("/listing"); // TODO pour le cas /home/signup continue de fonctionner. A refactorer en utilisant authSuccess
				}
			}}
			onToogleSignupForm={onToogleSignup}
			untouch={untouch}
		/>
	);
};

LoginContainer.propTypes = {
	location: PropTypes.object,
	untouch: PropTypes.func,
	onAuthSuccess: PropTypes.func,
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	onToogleSignupForm: PropTypes.func,
	acceptCookyPolicy: PropTypes.func,
	logout: PropTypes.func,
	showTopFacebook: PropTypes.bool,
	showBottomFacebook: PropTypes.bool,
	homeRedirection: PropTypes.bool,
	headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	history: PropTypes.shape({ push: PropTypes.func, replace: PropTypes.func }),
	brand: brandPropTypes,
};

const mapStateToProps = state => {
	return {
		brand: state.brand.code,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ untouch, acceptCookyPolicy, logout }, dispatch);
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(LoginContainer)
);
