import {
	CLEAR_PRODUCT_PAGE,
	FETCH_FICHE_PRODUIT_FAILURE,
	FETCH_FICHE_PRODUIT_SUCCESS,
} from "app/actionTypes";
import omit from "lodash/omit";
import { getDuration } from "app/utils/utils";
import {
	FETCH_SDP_ACCOMMODATION_DESCRIPTION_SUCCESS,
	FETCH_SDP_QUOTE_REQUEST,
	SAVE_SELECTED_SDP_PRODUCT,
} from "app/pages/SmartDP/smartDPActionTypes";
import { PRODUCT_PAGE_CODE_STATUS } from "app/constants";

const initialProductState = {
	status: PRODUCT_PAGE_CODE_STATUS.LOADING,
	id: "",
	photos: [],
	multimedia: [],
	highlights: [],
	name: "",
	adultOnly: false,
	isChildAgeRequired: false,
	category: 0,
	contractType: "",
	country: "",
	boards: [],
	region: "",
	badges: [],
	merchandisings: [],
	resort: "",
	specialOffer: {},
	tripAdvisor: {
		ratingURL: undefined,
		awardURL: undefined,
		rating: 0,
		reviewsCount: undefined,
	},
	expiration: {
		hour: undefined,
		day: undefined,
	},
	fromPriceType: {
		type: undefined,
		value: undefined,
	},
	expert: {
		loves: [],
	},
	accommodation: {
		intro: {
			headline: "",
			intro: [],
			facilities: [],
		},
		type: {
			headline: "",
			paragraphs: [],
			photo: {
				alt: "",
				url: "",
				thumbnail: "",
			},
			accommodationTypes: [],
			facilities: [],
		},
	},
	restaurant: {
		headline: "",
		intro: [],
		restaurants: [],
		facilities: [],
	},
	transportPartnership: {
		label: "",
		photo: {
			alt: "",
			url: "",
			thumbnail: "",
		},
		paragraphs: [],
	},
	gps: {},
	destination: {},
	experiences: [],
	highlightedExtra: {
		headline: "",
		subheading: "",
		highlightedExtras: [],
	},
	activity: {},
	usefulInformations: {},
	included: {},
	notIncluded: {},
	notFound: false,
};

export default function ficheProduitReducer(ficheProduit = initialProductState, action) {
	switch (action.type) {
		case FETCH_SDP_QUOTE_REQUEST:
		case CLEAR_PRODUCT_PAGE:
			return initialProductState;
		case FETCH_SDP_ACCOMMODATION_DESCRIPTION_SUCCESS: {
			return {
				...action.res.data,
				status: PRODUCT_PAGE_CODE_STATUS.SUCCESS,
			};
		}
		case SAVE_SELECTED_SDP_PRODUCT: {
			return action.accommodation;
		}
		case FETCH_FICHE_PRODUIT_SUCCESS: {
			const product = Object.assign({}, initialProductState, action.res.data);
			const now = new Date();
			const duration = getDuration(now.getTime(), product.endAt);

			const newFicheProduct = Object.assign({}, product, {
				tripAdvisor: {
					rating: product.tripadvisor.rating,
					reviewsCount: product.tripadvisor.num_reviews,
					awardURL: product.tripadvisor.awardURL,
					ratingURL: product.tripadvisor.ratingURL,
				},
				expiration: duration,
				notFound: false,
			});

			newFicheProduct.status = PRODUCT_PAGE_CODE_STATUS.SUCCESS;

			return omit(newFicheProduct, ["offers", "bookableExtras", "tripadvisor"]);
		}
		case FETCH_FICHE_PRODUIT_FAILURE: {
			return Object.assign({}, initialProductState, { notFound: true });
		}
		default:
			return ficheProduit;
	}
}
