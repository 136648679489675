import { combineValidators, hasLengthGreaterThan, isRequiredIf } from "revalidate";
import validationMessages from "app/utils/form/validationMessages";
import { number, object, string } from "yup";
/* .../max is required to use the most accurate validation available */
import { validatePhoneIntl } from "app/utils/utils";

export const validatePhoneNumberType = phoneNumberIntl => {
	if (typeof phoneNumberIntl !== "string") {
		return true; // if user wants to empty his number input it can be undefined
	}
	const isMatching = validatePhoneIntl(phoneNumberIntl);

	return isMatching;
};

export const fields = [
	"name.firstName",
	"name.lastName",
	"name.gender",
	"birthDate.day",
	"birthDate.month",
	"birthDate.year",
	"nationality",
	"address.line1",
	"address.line2",
	"address.postCode",
	"address.city",
	"address.country",
	"address.phone",
	"passport.passportNumber",
	"passport.countryIssue",
	"passport.passportExpirationDate",
];

export const validate = combineValidators({
	name: {
		firstName: hasLengthGreaterThan(1)({
			message: validationMessages.firstNameMinLength,
		}),
		lastName: hasLengthGreaterThan(1)({
			message: validationMessages.lastNameMinLength,
		}),
	},
	birthDate: {
		day: isRequiredIf(values => values && (values.birthDate.month || values.birthDate.year))({
			message: validationMessages.birthdateDayRequired,
		}),
		month: isRequiredIf(values => values && (values.birthDate.day || values.birthDate.year))({
			message: validationMessages.birthdateMonthRequired,
		}),
		year: isRequiredIf(values => values && (values.birthDate.day || values.birthDate.month))({
			message: validationMessages.birthdateYearRequired,
		}),
	},
});

export const defaultValues = {
	"honorific-prefix": "",
	"family-name": "",
	"given-name": "",
	birthdateDay: "",
	birthdateMonth: "",
	birthdateYear: "",
	nationality: "",
	"address-line1": "",
	"address-line2": "",
	"postal-code": "",
	"address-level2": "", // city
	"country-name": "",
	phone: "",
	// TODO SMS-OPTIN
	// phonemobile: "",
};

export const validationSchema = object().shape(
	{
		"family-name": string().min(2, validationMessages.lastNameMinLength),
		"given-name": string().min(2, validationMessages.firstNameMinLength),
		birthdateDay: number().when(["birthdateMonth", "birthdateYear"], {
			is: (month, year) => month || year,
			then: number().required(validationMessages.birthdateDayRequired),
		}),
		birthdateMonth: number().when(["birthdateDay", "birthdateYear"], {
			is: (day, year) => day || year,
			then: number().required(validationMessages.birthdateMonthRequired),
		}),
		birthdateYear: number().when(["birthdateDay", "birthdateMonth"], {
			is: (day, month) => day || month,
			then: number().required(validationMessages.birthdateYearRequired),
		}),
		phone: string().test(
			"phone (mobile or a fixed-line number)",
			validationMessages.phoneInvalid,
			numberIntl => validatePhoneNumberType(numberIntl)
		),
		// TODO SMS-OPTIN reactivate to enable validation
		// phone: string()
		// 	.test(
		// 		"should not be a mobile phone number",
		// 		validationMessages.phoneFixedIsMobile,
		// 		numberIntl =>
		// 			validatePhoneNumberType(
		// 				numberIntl,
		// 				[LIB_PHONE_NUMBER_TYPES.PERSONAL_NUMBER, LIB_PHONE_NUMBER_TYPES.MOBILE],
		// 				false
		// 			)
		// 	)
		// 	.test("should be a fixed-line number", validationMessages.phoneInvalid, numberIntl =>
		// 		validatePhoneNumberType(numberIntl, [
		// 			LIB_PHONE_NUMBER_TYPES.FIXED_LINE,
		// 			LIB_PHONE_NUMBER_TYPES.FIXED_LINE_OR_MOBILE,
		// 			LIB_PHONE_NUMBER_TYPES.UNDEFINED,
		// 		])
		// 	),
		// phonemobile: string()
		// 	.test(
		// 		"should not be a fixed-line number",
		// 		validationMessages.phoneMobileIsFixed,
		// 		numberIntl =>
		// 			validatePhoneNumberType(numberIntl, [LIB_PHONE_NUMBER_TYPES.FIXED_LINE], false)
		// 	)
		// 	.test("should be a mobile phone number", validationMessages.phoneInvalid, numberIntl =>
		// 		validatePhoneNumberType(numberIntl, [
		// 			LIB_PHONE_NUMBER_TYPES.PERSONAL_NUMBER,
		// 			LIB_PHONE_NUMBER_TYPES.MOBILE,
		// 			LIB_PHONE_NUMBER_TYPES.FIXED_LINE_OR_MOBILE,
		// 			LIB_PHONE_NUMBER_TYPES.UNDEFINED,
		// 		])
		// 	),
	},
	[
		["birthdateDay", "birthdateMonth"],
		["birthdateDay", "birthdateYear"],
		["birthdateMonth", "birthdateYear"],
	]
);
