import smoothscroll from "smoothscroll-polyfill";

if (typeof window !== "undefined") {
	smoothscroll.polyfill();
}

/**
 * disableSmoothScroll permet de désactiver l'effet smooth du scroll du fait laggué le scroll sur la page de listing
 */
export const scrollToElement = (element, offset = 0, disableSmoothScroll = true) => {
	if (element) {
		const positionToScroll = window.pageYOffset + element.getBoundingClientRect().top + offset;
		window.scrollTo({
			// Desactivation de l'effet smooth car possible cause des scroll sacadés
			behavior: disableSmoothScroll ? "auto" : "smooth", // https://developer.mozilla.org/en-US/docs/Web/API/ScrollToOptions/behavior
			left: 0,
			top: positionToScroll,
		});
	}
};

export const scrollToPosition = (top, left, disableSmoothScroll = false) => {
	window.scrollTo({
		behavior: disableSmoothScroll ? "auto" : "smooth",
		left,
		top,
	});
};
