import { composeValidators, hasLengthGreaterThan, isRequired, isRequiredIf } from "revalidate";
import validationMessages from "app/utils/form/validationMessages.js";
import {
	isCreditCardTypeAccepted,
	isCreditCardValid,
	isValidEmail,
	maxPassengersNumber,
	mustNotEqualZero,
	uniqueFullNameValidator,
} from "app/utils/form/validators.js";
import { determineCardType } from "app/utils/creditCardUtils";
import { CREDIT_CARDS_TYPES } from "app/constants";
import { isValidPhone } from "app/utils/form/validators";

export const passwordFieldValidator = composeValidators(
	isRequired({
		message: validationMessages.passwordRequired,
	}),
	hasLengthGreaterThan(5)({
		message: validationMessages.passwordMinLength,
	})
)("password");

export const emailFieldValidator = composeValidators(
	isRequired({
		message: validationMessages.emailRequired,
	}),
	isValidEmail({
		message: validationMessages.emailFormat,
	})
)("email");

export const firstnameValidator = composeValidators(
	isRequired({
		message: validationMessages.firstNameRequired,
	}),
	hasLengthGreaterThan(1)({
		message: validationMessages.firstNameMinLength,
	}),
	uniqueFullNameValidator({
		message: validationMessages.uniqueNamePassengers,
	})
)("firstname");

export const lastnameValidator = composeValidators(
	isRequired({
		message: validationMessages.lastNameRequired,
	}),
	hasLengthGreaterThan(1)({
		message: validationMessages.lastNameMinLength,
	}),
	uniqueFullNameValidator({
		message: validationMessages.uniqueNamePassengers,
	})
)("lastname");

export const creditCardValidator = (creditCardTypes = []) =>
	composeValidators(
		isRequiredIf(({ number }) => {
			return determineCardType(number) !== CREDIT_CARDS_TYPES.MAESTRO;
		})({
			message: validationMessages.creditcardNumberRequired,
		}),
		isCreditCardTypeAccepted(creditCardTypes)({
			message: validationMessages.creditCardTypeNotAccepted,
		}),
		isCreditCardValid()({
			message: validationMessages.creditCardInvalid,
		})
	)("credit card");

export const adultPassengerNumberValidator = composeValidators(
	mustNotEqualZero({
		message: validationMessages.adultsPassengers,
	}),
	maxPassengersNumber("infants", "children")({
		message: validationMessages.maxPassengersNumbers,
	})
)("adult");

export const phoneFieldValidator = composeValidators(
	isRequired({
		message: validationMessages.phoneRequired,
	}),
	isValidPhone({
		message: validationMessages.phoneInvalid,
	})
)("phone");
