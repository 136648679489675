import { combineValidators, isRequired } from "revalidate";
import validationMessages from "app/utils/form/validationMessages";

export const fields = ["phone"];

export const validate = combineValidators({
	phone: isRequired({
		message: validationMessages.phoneRequired,
	}),
});
