import { defineMessages } from "react-intl";

export default defineMessages({
	productIdNumber: {
		id: "error.product.id.number",
		description: "Error product id is number required",
		defaultMessage: "XXX",
	},
	answerRequired: {
		id: "error.answer.required",
		description: "Error message answer required",
		defaultMessage: "Your answser is required",
	},
	emailRequired: {
		id: "error.email.required",
		description: "Error message email required",
		defaultMessage: "Your email is required",
	},
	passwordRequired: {
		id: "error.password.required",
		description: "Error message password required",
		defaultMessage: "You must provide a password",
	},
	passwordMinLength: {
		id: "error.password.min.length",
		description: "Error password min length",
		defaultMessage: "Your password must contain at least 6 characters",
	},
	termsRequired: {
		id: "error.must.accept.terms",
		description: "Error terms must be accepted",
		defaultMessage: "Your must accept the PerfectStay privacy policy",
	},
	emailFormat: {
		id: "error.email.format",
		description: "Error message email format",
		defaultMessage: "Please check the format of your email",
	},
	emailNotAvailable: {
		id: "error.email.not.available",
		description: "Error message email not available",
		defaultMessage: "This email is already registered. Please choose another one.",
	},
	generic: {
		id: "error.generic",
		description: "Error message generic",
		defaultMessage: "An error occured. Please excuse the inconvenience and retry later.",
	},
	emailNotRegistered: {
		id: "error.email.not.registered",
		description: "Error message email not registered",
		defaultMessage: "This email is not registered",
	},
	facebookAccountWithoutPassword: {
		id: "error.facebook.account.without.password",
		description: "Error message facebook account without password",
		defaultMessage:
			"You have already created your account with facebook signup. No password has been set for this account, please login with facebook or use the reset password button",
	},
	passwordIncorrect: {
		id: "error.password.incorrect",
		description: "Error message password incorrect",
		defaultMessage: "Your password is incorrect",
	},
	actualPasswordIncorrect: {
		id: "error.actual.password.incorrect",
		description: "Error message actual password incorrect",
		defaultMessage: "Your actual password is incorrect",
	},
	sessionExpired: {
		id: "error.session.expired",
		description: "Error message session expired",
		defaultMessage: "Votre session a expiré. Veuillez vous reconnecter",
	},
	passwordDoNotMatch: {
		id: "error.password.do.not.match",
		description: "Error message password do not match",
		defaultMessage: "The two password do not match. Please enter the same password.",
	},
	genderRequired: {
		id: "error.gender.required",
		description: "Error message gender required",
		defaultMessage: "The gender is required",
	},
	insuranceRequired: {
		id: "error.payment.insurance.required",
		description: "Insurance required in payment page",
		defaultMessage: "You must choose an insurance to finalize your booking.",
	},
	firstNameRequired: {
		id: "error.firstName.required",
		description: "Error message firstName required",
		defaultMessage: "The firstname is required",
	},
	firstNameMinLength: {
		id: "error.firstName.minlength",
		description: "Error message firstName minlength",
		defaultMessage: "The firstname must have at least 2 characters",
	},
	lastNameRequired: {
		id: "error.lastName.required",
		description: "Error message lastname required",
		defaultMessage: "The lastname is required",
	},
	lastNameMinLength: {
		id: "error.lastName.minlength",
		description: "Error message lastName minlength",
		defaultMessage: "The lastname must have at least 2 characters",
	},
	addressLine1Required: {
		id: "error.address.line1.required",
		description: "Error message address required",
		defaultMessage: "The address is required",
	},
	addressPostCodeRequired: {
		id: "error.address.postCode.required",
		description: "Error message postcode required",
		defaultMessage: "The postcode is required",
	},
	addressCityRequired: {
		id: "error.address.city.required",
		description: "Error message city required",
		defaultMessage: "The city is required",
	},
	addressCityMaxLength: {
		id: "error.address.city.maxlength",
		description: "Error message city max length",
		defaultMessage: "The city must not exceed 50 characters",
	},
	addressCountryRequired: {
		id: "error.address.country.required",
		description: "Error message country required",
		defaultMessage: "The country is required",
	},
	phoneRequired: {
		id: "error.phone.required",
		description: "Error message phone required",
		defaultMessage: "The phone is required",
	},
	phoneInvalid: {
		id: "error.phone.invalid",
		description: "Error message phone invalid",
		defaultMessage: "The number is invalid for the country selected",
	},
	phoneMobileIsFixed: {
		id: "error.phone.mobile.is.fixed",
		description: "Error message phone mobile is fixed-line",
		defaultMessage: "Enter a fixed-line number",
	},
	phoneFixedIsMobile: {
		id: "error.phone.fixed.is.mobile",
		description: "Error message fixed-line number is mobile",
		defaultMessage: "Enter a mobile phone number",
	},
	departementCodeMinLength: {
		id: "error.departement.code.required",
		description: "Error message dep code required",
		defaultMessage: "The departement code is required",
	},
	creditcardAmountFormat: {
		id: "error.payment.card.amount.format",
		description: "Amount must be numeric",
		defaultMessage: "XXX",
	},
	creditcardAmountLessThan: {
		id: "error.payment.card.amount.lessThan",
		description: "Amount must be less than or equal amount to pay",
		defaultMessage: "XXX",
	},
	creditcardAmountRequired: {
		id: "error.payment.card.amount.required",
		description: "Error message card amount required",
		defaultMessage: "The credit card amount is required",
	},
	creditcardHolderNameRequired: {
		id: "error.payment.card.holder.name.required",
		description: "Error message card holder name required",
		defaultMessage: "The credit card holder name is required",
	},
	creditcardExpiryMonthRequired: {
		id: "error.payment.card.expiryMonth.required",
		description: "Error message card expiryMonth required",
		defaultMessage: "The credit card expiration month is required",
	},
	creditcardExpiryYearRequired: {
		id: "error.payment.card.expiryYear.required",
		description: "Error message card expiration year required",
		defaultMessage: "The credit card expiration year is required",
	},
	creditcardCvcRequired: {
		id: "error.payment.card.cvc.required",
		description: "Error message cvc required",
		defaultMessage: "The cvc is required",
	},
	creditcardNumberRequired: {
		id: "error.payment.card.number.required",
		description: "Error message credit card number required",
		defaultMessage: "The credit card number is required",
	},
	creditcardMaidenNameRequired: {
		id: "error.payment.maiden.name.required",
		description: "Error message maiden name required",
		defaultMessage: "The maiden name is required",
	},
	creditcardZipCodeOfBirthRequired: {
		id: "error.payment.zip.code.of.birth.required",
		description: "Error message zip code of birth required",
		defaultMessage: "The zip code of birth is required",
	},
	formGenericError: {
		id: "error.form.generic",
		description: "Error form generic",
		defaultMessage: "Some field are required or invalid. Please check the form again.",
	},
	creditCardInvalid: {
		id: "error.payment.credit.card.invalid",
		description: "Error payment credit card invalid",
		defaultMessage:
			"The credit card is invalid. Please check the number, expiration date and cvc.",
	},
	creditCardTypeNotAccepted: {
		id: "error.payment.credit.card.type.not.accepted.new",
	},
	issuerIdRequired: {
		id: "error.payment.issuerId.required",
		description: "Selected bank required",
		defaultMessage: "The selected bank is required",
	},
	invoiceTypeRequired: {
		id: "error.payment.invoiceType.required",
		description: "Invoice type required",
		defaultMessage: "The invoice type is required",
	},
	mustBeAuthenticated: {
		id: "error.must.be.authenticated",
		description: "Error no auth token",
		defaultMessage: "You must have an authorization to perform this action",
	},
	mustFillAllStepInSearchEngine: {
		id: "search.engine.error.all.field.required",
		description:
			"Error message to display when submitting the search engine form and one field is missing",
		defaultMessage: "Please fill all the required step before continuing",
	},
	adultsPassengers: {
		id: "error.search.engine.adults.min",
		description: "Error message when adult is 0",
		defaultMessage: "There must be at leat one adult among the passengers.",
	},
	babyExceedAdult: {
		id: "error.search.engine.infants.exceed.adults",
		description: "Error message when babies number exceed adults",
		defaultMessage: "The number of babies must not exceed the number of adults.",
	},
	maxPassengersNumbers: {
		id: "error.search.engine.max.passengers",
		description: "Error message for max passengers",
		defaultMessage: "The number of passengers must not exceed 9",
	},
	childrenAge: {
		id: "error.search.engine.children.age",
		description: "Error message for children age",
		defaultMessage: "The child must age between 0 and 18",
	},
	notIdentified: {
		id: "error.user.not.identified",
		description: "Error message when user is not identified",
		defaultMessage: "Sorry, we have not been able to identified you.",
	},
	birthdateDayRequired: {
		id: "error.birthdate.day",
		description: "Error message when birthday day is required",
		defaultMessage: "The birthdate day is required",
	},
	birthdateMonthRequired: {
		id: "error.birthdate.month",
		description: "Error message when birthday month is required",
		defaultMessage: "The birthdate month is required",
	},
	birthdateYearRequired: {
		id: "error.birthdate.year",
		description: "Error message when birthday year is required",
		defaultMessage: "The birthdate year is required",
	},
	atLeatOneFieldRequired: {
		id: "error.at.leat.one.field.required",
		description: "Error message when empty profile",
		defaultMessage: "XXX",
	},
	paymentErrorCreditCardInfo: {
		id: "error.payment.credit.card.info",
		description: "Payment error message credit card info",
		defaultMessage: "XXX",
	},
	paymentErrorUnknown: {
		id: "error.payment.error.unknown",
		description: "Payment error message unknown",
		defaultMessage: "XXX",
	},
	paymentErrorTechnical: {
		id: "error.payment.technical.error",
		description: "Payment error message technical error",
		defaultMessage: "XXX",
	},
	paymentErrorCreditCardRefused: {
		id: "error.payment.credit.card.refused.not.enough.balance",
		description: "Payment error message credit card refused",
		defaultMessage: "XXX",
	},
	paymentErrorPartnerRefused: {
		id: "error.payment.partner.refused",
		description: "Payment error message partner refused",
		defaultMessage: "XXX",
	},
	paymentErrorCreditCard3dsRefused: {
		id: "error.payment.credit.card.3ds.refused",
		description: "Payment error message credit card 3ds refused",
		defaultMessage: "XXX",
	},
	paymentErrorSessionExpired: {
		id: "error.payment.session.expired",
		description: "Payment error message session expired",
		defaultMessage: "XXX",
	},
	externalPaymentError: {
		id: "error.payment.external",
		description: "External payment error message",
		defaultMessage: "XXX",
	},
	bookingSessionExpired: {
		id: "error.booking.session.expired",
		description: "Error expired session during booking",
		defaultMessage: "XXX { link }",
	},
	bookingChildrenAgeDiscrepancy: {
		id: "error.booking.children.age.discrepancy",
		description: "Error children age discrepancy during booking",
		defaultMessage: "XXX",
	},
	bookingInfantsAgeDiscrepancy: {
		id: "error.booking.infants.age.discrepancy",
		description: "Error infants age discrepancy during booking",
		defaultMessage: "XXX",
	},
	paymentErrorFullProperty: {
		id: "error.payment.full.property",
	},
	paymentAgreementRefused: {
		id: "error.payment.consumer.credit.refused.error",
		description: "Error consumer credit refused",
		defaultMessage: "XXX",
	},
	bookingReferenceRequired: {
		id: "error.booking.reference.required.error",
		description: "Error booking reference required",
		defaultMessage: "XXX",
	},
	bookingReferenceLength: {
		id: "error.booking.reference.length.error",
		description: "Error booking reference length",
		defaultMessage: "XXX",
	},
	bookingEmailRequired: {
		id: "error.booking.email.required.error",
		description: "Error booking email required",
		defaultMessage: "XXX",
	},
	bookingNotFound: {
		id: "error.booking.not.found.error",
		description: "Error booking not found",
		defaultMessage: "XXX",
	},
	recoverNumberNotFound: {
		id: "error.recover.booking.number.not.found",
		description: "Error recover booking number not found",
		defaultMessage: "XXX",
	},
	sponsorFriendsEmailInvalid: {
		id: "error.sponsorship.friends.email.format.invalid",
		description: "Error sponsor friends email invalid",
		defaultMessage: "XXX",
	},
	sponsorNameRequired: {
		id: "error.sponsorship.sponsor.name.required",
		description: "Error sponsor name required",
		defaultMessage: "XXX",
	},
	friendEmailInvalid: {
		id: "error.sponsorship.friends.email.invalid",
		description: "Error sponsor friends email invalid",
		defaultMessage: "XXX",
	},
	atLeastOneFriendEmail: {
		id: "error.sponsorship.friends.email.at.leat.one",
		description: "Error sponsor friends email at least one",
		defaultMessage: "XXX",
	},
	uniqueNamePassengers: {
		id: "error.passengers.unique.invalid",
		description: "Not unique name passengers",
		defaultMessage: "XXX",
	},
	isAmount: {
		id: "error.amount.format",
		description: "Error must be a valid amount",
		defaultMessage: "XXX",
	},
});
