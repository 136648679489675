import { AUTH_SUCCESS, FETCH_PAYMENT_METHODS_SUCCESS, SET_PARTNER } from "app/actionTypes";
import { FETCH_PARTNER_DATA_SUCCESS } from "app/reducers/partnerActionTypes";
import get from "lodash/get";

const defaultState = {
	code: undefined,
	enablePromotionCode: false,
	enableSponsorship: false,
	strategy: "signupFirst",
	currencyCode: undefined,
	marketingBanner: {},
	marketing: {},
	fallbackSpecialOffer: {},
	availablePaymentMethods: [],
	avisVerifies: {},
	metaTags: [],
	theme: {},
	footer: {},
	useBrowserBackOnProductPage: false,
};

const _partnerReducer = (partner, partners) => {
	let strategy;
	if (partner.strategy) {
		strategy = partner.strategy;
	} else if (partners && partners[partner.code] && partners[partner.code].strategy) {
		strategy = partners[partner.code].strategy;
	} else {
		strategy = "signupFirst";
	}
	return {
		code: partner.code,
		strategy,
	};
};

export default function partnerReducer(partnerState = defaultState, action) {
	switch (action.type) {
		case FETCH_PARTNER_DATA_SUCCESS: {
			const data = action?.res?.data;
			const footerData = data?.footer;
			return {
				...partnerState,
				partnerRedirectionLink: footerData?.partnerRedirectionLink,
				socialNetworks: footerData?.socialNetworks,
				marketingBanner: data?.marketingBanner || {},
				marketing: data?.marketing || {},
				fallbackSpecialOffer: data?.fallbackSpecialOffer || {},
				avisVerifies: data?.avisVerifies || {},
				home: data?.home || {},
			};
		}
		case AUTH_SUCCESS: {
			return { ...partnerState, ..._partnerReducer(action.partner, action.partners) };
		}
		case FETCH_PAYMENT_METHODS_SUCCESS: {
			const data = get(action, "res.data") || {};
			return { ...partnerState, availablePaymentMethods: data.methods };
		}
		case SET_PARTNER:
			return {
				...partnerState,
				..._partnerReducer(
					{ code: action.partner, strategy: action.strategy },
					action.partners
				),
			};
		case "SET_STRATEGY":
			return Object.assign({}, partnerState, { strategy: action.strategy });
		default:
			return partnerState;
	}
}
