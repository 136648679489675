import axios from "axios";
import {
	FETCH_PROFILE_FAILURE,
	FETCH_PROFILE_REQUEST,
	FETCH_PROFILE_SUCCESS,
	SAVE_PROFILE,
} from "app/actionTypes";
import { getProfileApiUrl } from "src/@brand/appConstants";
import { getCredentials } from "app/utils/auth";
import { logout } from "app/pages/Auth/AuthActions";
import { sendTagOnError } from "app/utils/analytics";
import getHistory from "app/configureHistory";
import env from "app/utils/env";
import { HTTP_STATUS_CODES, STRATEGY } from "app/constants";
import {
	DELETE_PROFILE_REQUEST,
	DELETE_PROFILE_SUCCESS,
} from "app/pages/Account/Profile/profileActionTypes";

export const saveProfileOnPayment = (data, isSilent = false) => {
	let url = `${env("USER_AUTH_API_URL")}/me${isSilent ? "?silent=true" : ""}`;

	const { token } = getCredentials();
	const headers = {
		Authorization: token,
		"Content-type": "application/json",
	};
	return {
		type: SAVE_PROFILE,
		promise: axios.post(url, data, { headers }),
	};
};

/* isSilent permet de ne pas envoyer d'email de modification de compte et d'harceler le client */
export const saveProfile = ({ values, isSilent = false }) => {
	let url = `${env("USER_AUTH_API_URL")}/me${isSilent ? "?silent=true" : ""}`;
	const { token } = getCredentials();
	const headers = {
		Authorization: token,
		"Content-type": "application/json",
	};

	let birthDate;

	if (values.birthdateDay && values.birthdateMonth && values.birthdateYear) {
		birthDate = [
			values.birthdateYear,
			values.birthdateMonth > 9
				? values.birthdateMonth.toString()
				: "0" + values.birthdateMonth,
			values.birthdateDay > 9 ? values.birthdateDay.toString() : "0" + values.birthdateDay,
		].join("-");
	}

	const gender = values["honorific-prefix"];
	const country = values["country-name"];
	const nationality = values.nationality;

	const payload = {
		name: {
			firstName: values["given-name"],
			lastName: values["family-name"],
			...(gender && { gender }),
		},
		...(birthDate && { birthDate }),
		...(nationality && { nationality }),
		address: {
			line1: values["address-line1"],
			line2: values["address-line2"],
			postCode: values["postal-code"],
			city: values["address-level2"],
			...(country && { country }),
			phone: values.phone || "",
			// TODO SMS-OPTIN
			// phonemobile: values.phonemobile || "",
		},
	};

	// Should use promise middleware like the rest of the code base, but see issue below
	// https://github.com/jaredpalmer/formik/issues/2078#issue-530299569
	return () =>
		axios.post(url, payload, {
			headers,
		});
};

export const deleteProfile = () => {
	return dispatch => {
		const { token } = getCredentials();

		dispatch({
			type: DELETE_PROFILE_REQUEST,
		});

		return new Promise((resolve, reject) => {
			axios
				.delete(getProfileApiUrl(), {
					headers: { Authorization: token },
				})
				.then(res => {
					dispatch({
						type: DELETE_PROFILE_SUCCESS,
						profile: res.data,
					});

					resolve();
				})
				.catch(err => {
					if (err) {
						getHistory().push("/technical-error");
						reject(err.response);
					}
				});
		});
	};
};

export const getProfile = () => {
	return (dispatch, getState) => {
		const { token, tokenFromPartner } = getState().auth;

		dispatch({
			type: FETCH_PROFILE_REQUEST,
		});

		return new Promise((resolve, reject) => {
			axios
				.get(getProfileApiUrl(), {
					headers: { Authorization: tokenFromPartner ? tokenFromPartner : token },
				})
				.then(res => {
					dispatch({
						type: FETCH_PROFILE_SUCCESS,
						profile: res.data,
					});

					resolve();
				})
				.catch(err => {
					if (
						err &&
						err.response &&
						err.response.status &&
						(err.response.status === HTTP_STATUS_CODES.UNAUTHORIZED ||
							err.response.status === HTTP_STATUS_CODES.FORBIDDEN)
					) {
						dispatch(logout());
						if (getState().partner.strategy === STRATEGY.OAUTH) {
							getHistory().push("/technical-error/sso");
						}

						sendTagOnError(
							`Get ${getProfileApiUrl()} : ${err.message} - ${
								err.response.data.message
							}`
						);
					} else {
						dispatch({
							type: FETCH_PROFILE_FAILURE,
						});

						sendTagOnError(`${getProfileApiUrl()} : ${err.message}`);
					}

					reject(err.response);
				});
		});
	};
};
