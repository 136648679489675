import React from "react";
import PropTypes from "prop-types";
import { anyFieldTouched } from "app/utils/form-validator";
import { FormattedMessage } from "react-intl";
import Button from "app/pages/.shared/form/Button";
import Popover from "app/pages/.shared/Popover";
import { propTypes } from "redux-form";
import Input from "app/pages/.shared/form/Input";
import IconCircleTick from "app/pages/.shared/static/icons/IconCircleTick";
import "./RecoverBookingData.scss";

class RecoverBookingData extends React.PureComponent {
	render() {
		const {
			fields = {},
			handleSubmit,
			submitSucceeded,
			submitting,
			error,
			resetFormError,
			onRecoverSuccess,
		} = this.props;

		return submitSucceeded ? (
			<div className="recover-booking-data-form">
				<div className="recover-booking-data-form__success">
					<IconCircleTick height={50} width={50} style={{ marginBottom: "20px" }} />
					<FormattedMessage id="search.my.booking.recover.booking.success.message" />
					<div className="recover-booking-data-form__got-sms-button">
						<Button submit={false} design="primary" onClick={onRecoverSuccess}>
							<FormattedMessage id="search.my.booking.recover.booking.got.sms.button.label" />
						</Button>
					</div>
				</div>
			</div>
		) : (
			<div className="recover-booking-data-form">
				<h1 className="recover-booking-data-form__title">
					<FormattedMessage id="search.my.booking.recover.booking.form.title" />
				</h1>
				<div className="recover-booking-data-form__title-separator" />

				<div className="recover-booking-data-form__intro">
					<FormattedMessage id="search.my.booking.recover.booking.form.intro" />
				</div>

				<form
					noValidate
					className="recover-booking-data-form__form"
					onSubmit={handleSubmit}
				>
					<div className="recover-booking-data-form__input">
						<Input
							field={fields.phone}
							id="recover-booking-data-number"
							label={
								<FormattedMessage id="search.my.booking.recover.booking.number.placeholder" />
							}
							name="recover-booking-data-number"
						/>
					</div>
					<div className="recover-booking-data-form__button">
						<Button submit design="primary" loading={submitting}>
							<span style={{ textTransform: "uppercase" }}>
								<FormattedMessage id="search.my.booking.recover.booking.form.submit.button.label" />
							</span>
						</Button>
						<Popover onClose={resetFormError} isOpen={anyFieldTouched(fields) && error}>
							{error ? <FormattedMessage {...error} /> : ""}
						</Popover>
					</div>
				</form>
			</div>
		);
	}
}

RecoverBookingData.propTypes = {
	...propTypes,
	onRecoverSuccess: PropTypes.func,
};

export default RecoverBookingData;
