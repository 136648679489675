import React from "react";
import reduxFormSubmitter from "app/utils/form/reduxFormSubmitter";
import {
	fields,
	validate,
} from "app/pages/CorporateSite/RecoverBookingData/RecoverBookingDataSchema";
import validationMessages from "app/utils/form/validationMessages";
import { recoverBookingData } from "app/pages/CorporateSite/RecoverBookingData/RecoverBookingDataActions";
import RecoverBookingData from "app/pages/CorporateSite/RecoverBookingData/RecoverBookingData";
import { stopSubmit, untouch } from "redux-form";
import { bindActionCreators } from "redux";
import { sendTagOnRecoverBookingData } from "app/utils/analytics";
import { getShop } from "app/utils/shop";
import { getStore } from "app/configureStore";

class RecoverBookingDataContainer extends React.PureComponent {
	render() {
		return <RecoverBookingData {...this.props} />;
	}
}

const mapFormToPayload = values => {
	return {
		brandShop: {
			brandCode: getStore().getState().brand.code,
			shopCode: getShop(),
		},
		phone: values.phone,
	};
};

const getFormGlobalError = errors => {
	let _error = validationMessages.formGenericError;
	if (errors.phone) {
		_error = errors.phone;
	}
	return _error;
};

const beforeSubmit = values => {
	sendTagOnRecoverBookingData({
		phone: values.phone,
	});
};

const onSubmitFail = (response, ownProps, reject) => {
	let _error = validationMessages.generic;

	if (response.status === 404) {
		_error = validationMessages.recoverNumberNotFound;
	}

	reject({ _error });
};

const mapStateToProps = state => {
	return {
		submitSucceeded: state.form["recoverBookingData-form"].submitSucceeded,
	};
};

const mapDispatchToProps = dispatch => {
	return Object.assign(
		{},
		{
			resetFormError: () => {
				dispatch(untouch("recoverBookingData-form", "phone"));
				// Supprimer les erreurs formulaires https://github.com/erikras/redux-form/issues/2604#issuecomment-282670843
				dispatch(stopSubmit("recoverBookingData-form", {}));
			},
		},
		bindActionCreators({ untouch }, dispatch)
	);
};

const reduxFormSubmitterConfig = {
	form: "recoverBookingData-form",
	fields,
	validateForm: validate,
	mapFormToPayload,
	actionCreator: recoverBookingData,
	beforeSubmit,
	getFormGlobalError,
	onSubmitFail,
};

export default reduxFormSubmitter(reduxFormSubmitterConfig, mapStateToProps, mapDispatchToProps)(
	RecoverBookingDataContainer
);
