import React from "react";
import validationMessages from "app/utils/form/validationMessages";
import { reduxForm, stopSubmit } from "redux-form";
import { sendTagOnError } from "app/utils/analytics";
import { isDeepUndefined } from "app/utils/utils";
import isUndefined from "lodash/isUndefined";
import { getStore } from "app/configureStore";
import { BOOK_STATUS, PRE_BOOK_CODE_STATUS } from "app/constants";

/**
 * S'utilise comme le HOC reduxForm mais avec les différences suivantes :
 * - pour la requete du submit, passer un promise action creator via reduxFormSubmitterConfig.actionCreator
 * - possibilité de faire des traitements avant la validation du formulaire comme un envoi de tag via reduxFormSubmitterConfig.beforeValidateForm
 * - validation du formulaire via reduxFormSubmitterConfig.validateForm.
 *   Les erreurs sont remontées au formulaire après un clic sur le bouton de soumission mais avant l'envoi de la requête
 * - transformer les données du formulaire avant leur envoi via reduxFormSubmitterConfig.mapFormToPayload
 * - lancer un traitement avant la soumission du formulaire
 * - gérer le succès de la soumission du formulaire via reduxFormSubmitterConfig.onSubmitSuccess
 *
 * Ce HOC ajout de nouveaux props suivants :
 * - submitSucceeded : true si la soumission du formulaire a reussi. Idéal pour afficher un message de succès sans devoir persister un état dans le store
 *   Nécessite de rajouter une config dans le plugin redux form :
 *   "profile-form": (state, action) => {
			state.submitSucceeded = false;
			switch (action.type) {
				case reduxActionTypes.INITIALIZE:
					return {
						...state,
						submitSucceeded: false,
						_error: undefined
					};
				case reduxActionTypes.STOP_SUBMIT:
					return {
						...state,
						submitSucceeded: action.errors ? false : true
					};
				case reduxActionTypes.CHANGE:
					return {
						...state,
						_error: undefined
					};
				default:
					return state;
			}
	}
 * @param reduxFormSubmitterConfig
 *            {
 * 				form (requis)
 *				fields (requis)
 *				actionCreator (optionel)
 *				beforeValidateForm(values) (optionel)
 *				preValidate(values, props) (optionel) retourne une message d'erreur qui sera rejeté par la promesse ou undefined
 *				validateForm(values, ownProps) (optionel)
 *				getFormGlobalError(errors) (optionel) permet de choisir l'unique message d'erreur à afficher lorsqu'il y a plusieurs erreurs
 *				mapFormToPayload(values, ownProps) (optionel)
 *  			beforeSubmit(values, ownProps, dispatch) optionel)
 *				onSubmitSuccess(response, ownProps, dispatch, values) (optionel)
 *				onSubmitFail(res, ownProps, reject, dispatch, values) possibilité de dispatcher des actions ou de rejeter des erreurs qui seront remontées dans le formulaire
 * 			}
 * @param mapStateToProps mapStateToProps du connect de react-redux
 * @param mapDispatchToProps mapDispatchToProps du connect de react-redux
 */
export default (reduxFormSubmitterConfig, mapStateToProps, mapDispatchToProps) => {
	return Component => {
		const reduxFormConfig = {
			fields: reduxFormSubmitterConfig.fields,
			form: reduxFormSubmitterConfig.form,
		};

		const _mapStateToProps = (state, props) => {
			let form;

			if (state.form[reduxFormSubmitterConfig.form]) {
				form = props.formKey
					? state.form[reduxFormSubmitterConfig.form][props.formKey]
					: state.form[reduxFormSubmitterConfig.form];
			}

			return Object.assign({}, mapStateToProps ? mapStateToProps(state, props) : {}, {
				submitSucceeded: form ? form.submitSucceeded : false,
			});
		};

		const _mapDispatchToProps = (dispatch, ownProps) => {
			return Object.assign(
				{},
				mapDispatchToProps ? mapDispatchToProps(dispatch, ownProps) : {},
				{
					onSubmit: values => {
						const state = getStore().getState();

						// 1. action pre validation
						if (reduxFormSubmitterConfig.beforeValidateForm) {
							reduxFormSubmitterConfig.beforeValidateForm(values, dispatch);
						}

						return new Promise((resolve, reject) => {
							let preValidateErrors;

							if (reduxFormSubmitterConfig.preValidate) {
								preValidateErrors = reduxFormSubmitterConfig.preValidate(
									values,
									ownProps
								);
							}

							if (!isUndefined(preValidateErrors)) {
								reject({ _error: preValidateErrors });
								sendTagOnError(preValidateErrors.id);
							} else {
								let errors;

								// 2. validation du formulaire
								if (reduxFormSubmitterConfig.validateForm) {
									errors = reduxFormSubmitterConfig.validateForm(
										values,
										ownProps
									);
								}

								if (!isDeepUndefined(errors)) {
									sendTagOnError(
										`Validation errors on ${
											reduxFormSubmitterConfig.form
										} : ${JSON.stringify(errors)}`
									);
									// 3. Remontée des erreurs dans le formulaire
									let _error = validationMessages.formGenericError;
									if (reduxFormSubmitterConfig.getFormGlobalError) {
										_error = reduxFormSubmitterConfig.getFormGlobalError(
											errors
										);
									}

									reject({ _error, ...errors });
								} else {
									// 4. Traitement pre soumission
									if (
										typeof reduxFormSubmitterConfig.beforeSubmit === "function"
									) {
										reduxFormSubmitterConfig.beforeSubmit(
											values,
											ownProps,
											dispatch
										);
									}

									// 5.1 Si formulaire valide alors on vide les erreurs
									dispatch(stopSubmit(reduxFormSubmitterConfig.form, {}));

									// 5.2 transformation éventuelle des données avant soumission
									let actionPayload = values;
									if (reduxFormSubmitterConfig.mapFormToPayload) {
										actionPayload = reduxFormSubmitterConfig.mapFormToPayload(
											values,
											ownProps,
											state
										);
									}

									// 6.1 Soumission du formulaire via une requête XHR
									if (reduxFormSubmitterConfig.actionCreator) {
										dispatch(
											reduxFormSubmitterConfig.actionCreator(
												actionPayload,
												ownProps,
												dispatch
											)
										).then(res => {
											if (
												res.status === 200 &&
												res.data &&
												res.data.status &&
												res.data.status !== "SUCCESS" &&
												res.data.status !== BOOK_STATUS.DUPLICATE_BOOKING &&
												res.data.status !==
													BOOK_STATUS.EXTERNAL_REDIRECT_REQUEST &&
												res.data.status !== "WARNING" &&
												res.data.status !==
													"WARNING_BOOKING_OK_FULLPAYMENT_FAILED" &&
												res.data.status !==
													"WARNING_BOOKING_FAILED_FULLPAYMENT_FAILED" &&
												res.data.status !== "TECHNICAL_FAILURE" &&
												res.data.status !==
													PRE_BOOK_CODE_STATUS.FULL_FLIGHT &&
												res.data.status !==
													PRE_BOOK_CODE_STATUS.FULL_PROPERTY &&
												res.data.status !==
													PRE_BOOK_CODE_STATUS.FULL_TRANSFER &&
												res.data.status !== "EXTERNAL_HTML_REQUEST" &&
												res.data.status !==
													"WARNING_BOOKING_FAILED_FULLPAYMENT_OK"
											) {
												sendTagOnError(
													`${reduxFormSubmitterConfig.actionCreator.toString()} [${
														res.status
													}] : ${res.data.status}`
												);
												if (reduxFormSubmitterConfig.onSubmitFail) {
													reduxFormSubmitterConfig.onSubmitFail(
														res,
														ownProps,
														reject,
														dispatch,
														values
													);
												} else {
													reject({ _error: validationMessages.generic });
												}
											} else if (res.status === 200) {
												if (reduxFormSubmitterConfig.onSubmitSuccess) {
													resolve(
														reduxFormSubmitterConfig.onSubmitSuccess(
															res,
															ownProps,
															dispatch,
															values,
															state
														)
													);
												} else {
													resolve(res.data);
												}
											} else {
												if (reduxFormSubmitterConfig.onSubmitFail) {
													reduxFormSubmitterConfig.onSubmitFail(
														res,
														ownProps,
														reject,
														dispatch,
														values
													);
												} else {
													reject({ _error: validationMessages.generic });
												}
												sendTagOnError(
													`${res.config.method} ${res.config.url} : ${
														res.status
													} - ${res.statusText}`
												);
											}
										});
									} else if (reduxFormSubmitterConfig.onSubmitSuccess) {
										// 6.2 ou sans requête XHR
										resolve(
											reduxFormSubmitterConfig.onSubmitSuccess(
												ownProps,
												dispatch,
												values
											)
										);
									} else {
										resolve();
									}
								}
							}
						});
					},
				}
			);
		};

		const ReduxContainer = reduxForm(reduxFormConfig, _mapStateToProps, _mapDispatchToProps)(
			Component
		);

		return class extends React.Component {
			// eslint-disable-line  react/prefer-stateless-function
			render() {
				return <ReduxContainer {...this.props} />;
			}
		};
	};
};
