import PropTypes from "prop-types";
import React, { useContext } from "react";
import "./pages/.styles/index.scss";
import HeadMetadata from "app/utils/metas/HeadMetadata";
import { brandPropTypes, strategyShape } from "app/utils/propTypes";
import {
	BRANDS,
	BRANDS_WITH_BREAK,
	BRANDS_WITH_LISTING,
	PARTNER_CODE_WITH_BREAK,
	PRODUCTS_VISIBILITY,
	STRATEGY,
} from "app/constants";
import { StickyContainer } from "react-sticky";
import { LoadingBar } from "app/pages/.shared/LoadingBar/LoadingBar";
import { matchPath, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { dispatchOnQueries, redirectOnMerch, redirectOnOperatorLogin } from "app/utils/routerUtils";
import FooterContainer from "app/pages/Footer/FooterContainer";
import {
	AsyncAccountContainer,
	AsyncAuthBookingLayerContainer,
	AsyncAuthContainer,
	AsyncBooking,
	AsyncBookingHeaderContainer,
	AsyncBreakContainer,
	AsyncFaqContainer,
	AsyncFicheProduitContainer,
	AsyncHeaderContainer,
	AsyncHomeContainer,
	AsyncHomeHeaderContainer,
	AsyncLandingAuthContainer,
	AsyncLandingPageContainer,
	AsyncLandingProductContainer,
	AsyncListingContainer,
	AsyncMerchandisingContainer,
	AsyncNotFound,
	AsyncPaymentExternalRedirect,
	AsyncPriceMatchGuarantee,
	AsyncSearchBookingPage,
	AsyncSearchMyBookingContainer,
	AsyncSimpleHeaderContainer,
	AsyncSmartDPContainer,
	AsyncStaticPageContainer,
	AsyncTechnicalErrorContainer,
	AsyncUnsubscribeContainer,
} from "app/routes";
import BookWithConfidence from "app/pages/.shared/BookWithConfidence/BookWithConfidence";
import AsidePanelContainer from "app/pages/.shared/AsidePanel/AsidePanelContainer";
import { AsideMenuAccountContainer } from "app/pages/.shared/AsideMenu/AsideMenuAccountContainer";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import AppGlobalsContext from "app/AppGlobalsContext";
import RequireSignupFirst from "app/pages/Auth/RequireSignupFirst";

const PerfectApp = ({
	authLayer,
	brand,
	isWhiteLabel,
	shop,
	isAuthenticated,
	productsVisibility,
	strategy,
	partnerCode,
	showSmartDPOnRootUrl,
}) => {
	let routes;

	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const shouldBreakPage =
		productsVisibility !== PRODUCTS_VISIBILITY.PREVIEW &&
		(BRANDS_WITH_BREAK.includes(brand) || PARTNER_CODE_WITH_BREAK.includes(partnerCode));

	if (brand === BRANDS.XX) {
		routes = (
			<StickyContainer>
				<Switch>
					<Route path={["/home", "/faq"]} component={null} />
					<Route path="/account" component={AsyncHeaderContainer} />
					<Route
						path="/listing"
						render={() => {
							if (strategy === STRATEGY.SIGNUP_FIRST && !isAuthenticated) {
								return null;
							}

							return <AsyncHeaderContainer />;
						}}
					/>
					<Route
						path={["/not-found", "/merch", "/technical-error"]}
						component={AsyncHeaderContainer}
					/>
					<Route path="/booking" component={AsyncBookingHeaderContainer} />
					<Route path="/priceMatchGuarantee" component={AsyncHeaderContainer} />
					<Route
						path="/:productUri"
						render={() => {
							if (strategy === STRATEGY.SIGNUP_FIRST && !isAuthenticated) {
								return null;
							}

							return <AsyncHeaderContainer />;
						}}
					/>
				</Switch>

				<Switch>
					<Route
						path="/home"
						render={props => {
							dispatchOnQueries(props);

							return (
								<Redirect
									to={{
										pathname: `/`,
									}}
								/>
							);
						}}
					/>

					<Route
						path="/account"
						render={props => {
							if (!isAuthenticated) {
								return (
									<Redirect
										to={{
											pathname: `/`,
										}}
									/>
								);
							}

							return <AsyncAccountContainer {...props} />;
						}}
					/>

					<Route
						path="/auth"
						render={() => {
							return (
								<Redirect
									to={{
										pathname: `/`,
									}}
								/>
							);
						}}
					/>

					<Route
						path="/booking"
						render={props => {
							return <AsyncBooking {...props} />;
						}}
					/>
					<Route
						path="/external-redirect"
						render={props => {
							return <AsyncPaymentExternalRedirect {...props} />;
						}}
					/>
					<Route
						path="/not-found"
						render={props => {
							return <AsyncNotFound {...props} />;
						}}
					/>
					<Route path="/technical-error" component={AsyncTechnicalErrorContainer} />

					<Route
						path="/schizophrene"
						render={props => {
							redirectOnOperatorLogin(props);
						}}
					/>

					<Route
						path="/listing"
						render={props => {
							dispatchOnQueries(props);
							return <AsyncListingContainer {...props} />;
						}}
					/>

					<Route
						path="/merch"
						render={props => {
							dispatchOnQueries(props);
							redirectOnMerch({ brand, ...props });

							return <AsyncMerchandisingContainer {...props} />;
						}}
					/>

					<Route
						path="/priceMatchGuarantee"
						render={props => {
							return <AsyncPriceMatchGuarantee {...props} />;
						}}
					/>

					<Route path="/faq">
						<AsyncFaqContainer />
					</Route>

					<Route path="/page">
						<AsyncStaticPageContainer />
					</Route>

					<Route path="/sdp">
						<AsyncSmartDPContainer />
					</Route>

					<Route
						path="/:productUri"
						render={props => {
							dispatchOnQueries(props);

							return (
								<RequireSignupFirst
									{...props}
									renderComponent={props => {
										return <AsyncFicheProduitContainer {...props} />;
									}}
								/>
							);
						}}
					/>

					<Route
						exact
						path="/"
						render={props => {
							dispatchOnQueries(props); // pour prerenseigner le champ email

							return <AsyncSearchBookingPage {...props} />;
						}}
					/>

					<Redirect from="/*" to="/" />
				</Switch>
			</StickyContainer>
		);
	} else {
		routes = (
			<StickyContainer>
				<Switch>
					<Route
						path="/home"
						render={() => {
							return (
								brand !== BRANDS.AF &&
								brand !== BRANDS.TO &&
								brand !== BRANDS.AX &&
								brand !== BRANDS.EK && <AsyncHomeHeaderContainer />
							);
						}}
					/>
					<Route path="/auth" component={null} />
					<Route path="/unsubscribe" component={AsyncHeaderContainer} />
					<Route path="/account" component={AsyncHeaderContainer} />
					<Route path="/landing-page" component={null} />
					<Route path="/schizophrene" component={AsyncHeaderContainer} />
					<Route path="/priceMatchGuarantee" component={AsyncHeaderContainer} />
					<Route path="/booking" component={AsyncBookingHeaderContainer} />
					<Route path="/external-redirect" component={null} />
					<Route path="/not-found" component={AsyncHeaderContainer} />
					<Route path="/technical-error" component={AsyncHeaderContainer} />
					<Route path="/magazine" component={null} />
					<Route path="/lp" component={null} />
					<Route path="/faq" component={null} />
					<Route path="/sdp" component={null} />
					<Route path="/listing">{brand !== BRANDS.TZ && <AsyncHeaderContainer />}</Route>
					<Route path="/merch" component={AsyncHeaderContainer} />
					<Route path="/product/id/:productId" component={AsyncHeaderContainer} />
					<Route path="/product/:productUri" component={AsyncSimpleHeaderContainer} />
					<Route path="/break" component={AsyncHeaderContainer} />
					<Route
						path="/booking-auth"
						component={isMobile ? null : AsyncHeaderContainer}
					/>
					<Route
						path="/booking-search"
						component={isMobile ? null : AsyncHeaderContainer}
					/>
					<Route path="/:productUri" component={AsyncHeaderContainer} />
					{!showSmartDPOnRootUrl && (
						<Route>
							<AsyncHeaderContainer />
						</Route>
					)}
				</Switch>

				<Switch>
					<Route
						exact
						path="/"
						render={props => {
							let redirectUrl;

							if (showSmartDPOnRootUrl) {
								// pour une brand devant rediriger vers la page search smartdp, on redirige vers /
								// react-router affichera le composant de la page search du sdp (voir PerfectApp)
								redirectUrl = "";
							} else if (strategy === STRATEGY.TRANSACTION_FIRST) {
								redirectUrl = `not-found`;

								if (BRANDS_WITH_LISTING.includes(brand)) {
									redirectUrl = "listing";
								}
							} else if (strategy === STRATEGY.OAUTH) {
								redirectUrl = "listing";
							} else if (strategy === STRATEGY.SIGNUP_FIRST) {
								if (isAuthenticated) {
									redirectUrl = "listing";
								} else {
									redirectUrl = "home/signup";
								}
							}

							if (redirectUrl) {
								return (
									<Redirect
										to={{
											pathname: redirectUrl,
										}}
									/>
								);
							} else if (showSmartDPOnRootUrl) {
								return <AsyncSmartDPContainer {...props} />;
							}
							return <AsyncHomeContainer {...props} />;
						}}
					/>
					{isWhiteLabel && (
						<Route
							path="/home"
							render={props => {
								dispatchOnQueries(props);

								return <AsyncHomeContainer {...props} />;
							}}
						/>
					)}
					{isWhiteLabel && (
						<Route
							path="/auth"
							render={props => {
								return <AsyncAuthContainer {...props} />;
							}}
						/>
					)}
					{isWhiteLabel && (
						<Route
							path="/unsubscribe"
							render={props => {
								dispatchOnQueries(props);

								return <AsyncUnsubscribeContainer {...props} />;
							}}
						/>
					)}
					{(isWhiteLabel || isAuthenticated) && (
						<Route
							path="/account"
							render={props => {
								return <AsyncAccountContainer {...props} />;
							}}
						/>
					)}

					{isWhiteLabel && (
						<Route
							path="/landing-page/:operationCode"
							render={props => {
								dispatchOnQueries(props);

								return <AsyncLandingAuthContainer {...props} />;
							}}
						/>
					)}

					{brand === BRANDS.AF && (
						<Route path="/ourcommitments">
							<Redirect to={{ pathname: "/page/ourcommitments" }} />
						</Route>
					)}

					<Route
						path="/product/id/:productId"
						render={props => {
							// TODO remettre après coronavirus
							if (shouldBreakPage) {
								return <Redirect to={{ pathname: "/break" }} />;
							}

							dispatchOnQueries(props);

							return (
								<RequireSignupFirst
									{...props}
									renderComponent={props => {
										return <AsyncFicheProduitContainer {...props} />;
									}}
								/>
							);
						}}
					/>

					{isWhiteLabel && (
						<Route
							path="/product/:productUri"
							render={props => {
								dispatchOnQueries(props);
								return <AsyncLandingProductContainer {...props} />;
							}}
						/>
					)}

					{/* Routes communes */}
					<Route
						path="/schizophrene"
						render={props => {
							redirectOnOperatorLogin(props);
						}}
					/>
					<Route
						path="/priceMatchGuarantee"
						render={props => {
							return <AsyncPriceMatchGuarantee {...props} />;
						}}
					/>
					<Route
						path="/booking"
						render={props => {
							return <AsyncBooking {...props} />;
						}}
					/>
					<Route
						path="/external-redirect"
						render={props => {
							return <AsyncPaymentExternalRedirect {...props} />;
						}}
					/>
					<Route
						path="/not-found"
						render={props => {
							return <AsyncNotFound {...props} />;
						}}
					/>
					<Route path="/technical-error" component={AsyncTechnicalErrorContainer} />

					<Route
						path="/lp"
						render={props => {
							return <AsyncLandingPageContainer {...props} />;
						}}
					/>
					<Route
						path="/listing"
						render={props => {
							// TODO remettre après coronavirus
							if (shouldBreakPage) {
								return <Redirect to={{ pathname: "/break" }} />;
							}
							dispatchOnQueries(props);
							return <AsyncListingContainer {...props} />;
						}}
					/>
					<Route
						path="/merch"
						render={props => {
							// TODO remettre après coronavirus
							if (shouldBreakPage) {
								return <Redirect to={{ pathname: "/break" }} />;
							}
							dispatchOnQueries(props);
							redirectOnMerch({ brand, ...props });

							return <AsyncMerchandisingContainer {...props} />;
						}}
					/>

					<Route path="/break">
						<AsyncBreakContainer />
					</Route>

					<Route path="/faq">
						<AsyncFaqContainer />
					</Route>

					<Route path="/page/:slug">
						<AsyncStaticPageContainer />
					</Route>

					<Route path="/sdp">
						<AsyncSmartDPContainer />
					</Route>

					<Route path="/booking-auth">
						<AsyncAuthBookingLayerContainer />
					</Route>

					<Route path="/booking-search">
						<AsyncSearchMyBookingContainer />
					</Route>

					<Route
						path="/:productUri"
						render={props => {
							// TODO remettre après coronavirus
							if (shouldBreakPage) {
								return <Redirect to={{ pathname: "/break" }} />;
							}

							dispatchOnQueries(props);

							return (
								<RequireSignupFirst
									{...props}
									renderComponent={props => {
										return <AsyncFicheProduitContainer {...props} />;
									}}
								/>
							);
						}}
					/>
				</Switch>
			</StickyContainer>
		);
	}

	return (
		<React.Fragment>
			<LoadingBar />

			<HeadMetadata page="default" />
			<AsidePanelContainer>
				<AsideMenuAccountContainer />
			</AsidePanelContainer>
			{authLayer}

			{routes}
			<Route
				render={({ location }) =>
					!matchPath(location.pathname, {
						path: [
							"/external-redirect",
							"/faq",
						] /** array of paths where we hide footer */,
					}) && (
						<React.Fragment>
							<BookWithConfidence shop={shop} />
							<FooterContainer />
						</React.Fragment>
					)
				}
			/>
		</React.Fragment>
	);
};

PerfectApp.propTypes = {
	authLayer: PropTypes.element,
	brand: brandPropTypes,
	match: PropTypes.object,
	productsVisibility: PropTypes.string,
	shop: PropTypes.string,
	strategy: strategyShape,
	isWhiteLabel: PropTypes.bool,
	isAuthenticated: PropTypes.bool,
	partnerCode: PropTypes.string,
	showSmartDPOnRootUrl: PropTypes.bool,
};

export default withRouter(PerfectApp);
