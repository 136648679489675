import { createSelector } from "reselect";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import first from "lodash/first";
import last from "lodash/last";
import get from "lodash/get";
import { isDepositEnabled } from "app/pages/.shared/Deposit/depositSelector";
import { getDayCount, getHoursCount } from "app/utils/utils";
import { getAllCouponsTotal } from "app/pages/Account/MyCoupons/couponSelector";
import { getSelectedActivitiesForPriceDetails } from "app/pages/Booking/Quotation/Activity/QuotationActivitySelectors";
import { calculateFlexIncluded } from "app/utils/quotationUtils";

export const getOffer = state => state.booking.offer;
const getAccommodation = state => state.booking.accommodation;
const getTransfer = state => state.booking.transfer;
const getBoard = state => state.booking.board;
export const getFlight = state => state.booking.flight;
const getFlightInbound = state => getFlight(state).inbound;
const getFlightInboundAirline = state => {
	const flightInbound = getFlightInbound(state);

	return flightInbound && flightInbound.airline;
};
export const getFlightInboundAirlineCode = state => {
	const flightInboundAirline = getFlightInboundAirline(state);

	return flightInboundAirline ? flightInboundAirline.code : "";
};
const getInsurance = state => state.booking.insurance;
export const getInsuranceFlex = state => state.booking.insuranceFlex;
const getPassengers = state => state.booking.passengers;
const getLuggage = state => state.booking.luggage;
const getQuotationItems = state => state.quotation.quotationItems;
const getDeltaBasePrice = state => state.quotation.deltaBasePrice;
const getAdults = state => state.booking.adults;
const getChildren = state => state.booking.children;
const getBabies = state => state.booking.infants;
const getOccupancies = state => state.booking.occupancies;
export const getBookingDepartureDate = state => state.booking.departureDate;

export const getHasSpecialOffer = state => state.booking.hasSpecialOffer;

export const isBookingEligibleForDeposit = createSelector(
	[isDepositEnabled, getBookingDepartureDate],
	(isDepositEnabled, departureDate) => {
		if (isDepositEnabled && departureDate) {
			const bookingDateISO = new Date().toISOString();
			const departureDateISO = new Date(departureDate).toISOString();
			return getHoursCount(bookingDateISO, departureDateISO) / 24 > 30;
		}
		return false;
	}
);

export const getBookingOfferType = createSelector(
	[getOffer],
	(offer = {}) => {
		return offer.type;
	}
);

export const getBookingFeesAmount = createSelector(
	[getQuotationItems],
	(quotationItem = []) => {
		const bookingFeesItem = find(quotationItem, item => item.type === "BOOKING_FEES");
		return bookingFeesItem ? bookingFeesItem.price : 0;
	}
);

export const getRoomUpgrade = createSelector(
	[getAccommodation],
	(accommodation = {}) => {
		return !isEmpty(accommodation) && !accommodation.included ? accommodation : undefined;
	}
);

export const getBoardUpgrade = createSelector(
	[getBoard],
	(board = {}) => {
		return !isEmpty(board) && !board.included ? board : undefined;
	}
);

export const getFlightUpgrade = createSelector(
	[getFlight],
	(flight = {}) => {
		return !isEmpty(flight) && !flight.included ? flight : undefined;
	}
);

export const getTransferUpgrade = createSelector(
	[getTransfer],
	(transfer = {}) => {
		return !isEmpty(transfer) && !transfer.included ? transfer : undefined;
	}
);

export const getLuggageTotalPrice = createSelector(
	[getLuggage, getFlight],
	(luggage = undefined, flight = {}) => {
		const flightBagageOptions = get(flight, "flightOptions.flightBagageOptions");

		if (luggage === undefined || luggage === 0) {
			return 0;
		} else if (luggage >= 1 && flightBagageOptions) {
			const selectedBagageOption = flightBagageOptions.find(
				flightBagageOption => flightBagageOption.quantity === luggage
			);
			return selectedBagageOption ? selectedBagageOption.pricePerBooking : 0;
		}
		return 0;
	}
);

export const calculateOutboundArrivalDayCount = createSelector(
	[getFlight],
	(flight = {}) => {
		const outbound = flight.outbound;

		if (!outbound || !outbound.legs || outbound.legs.length === 0) {
			return 0;
		}

		const outboundDepartureDate = first(outbound.legs).from.takeOffDate;
		const outboundArrivalDate = last(outbound.legs).to.landingDate;

		return getDayCount(outboundDepartureDate, outboundArrivalDate);
	}
);

export const calculateInboundArrivalDayCount = createSelector(
	[getFlight],
	(flight = {}) => {
		const inbound = flight.inbound;

		if (!inbound || !inbound.legs || inbound.legs.length === 0) {
			return 0;
		}

		const inboundDepartureDate = first(inbound.legs).from.takeOffDate;
		const inboundArrivalDate = last(inbound.legs).to.landingDate;

		return getDayCount(inboundDepartureDate, inboundArrivalDate);
	}
);

export const calculateActivitiesUpgradePrice = createSelector(
	[getSelectedActivitiesForPriceDetails],
	(activities = []) => {
		return activities.reduce((acc, activity) => {
			return (
				acc +
				activity.guests.reduce((guestAcc, guest) => {
					const guestCount = guest.guestsCount;

					const guestPrices = guest.pricePerQuantity[guestCount] || {};
					const guestPrice = guestPrices.price || 0;
					const guestDiscount = guestPrices.discount || 0;

					return guestAcc + guestPrice + guestDiscount;
				}, 0)
			);
		}, 0);
	}
);

/**
 * Calcule le total du panier de le page quote en tenant compte de :
 * - supplement chambre
 * - supplement pension
 * - supplement vol
 * - supplement transfert
 * - supplement activites
 * - supplement bagages
 * C'est le total utilisé pour calculer le supplément flex
 */
export const calculateQuoteBaseAndExtrasTotal = createSelector(
	[
		getQuotationItems,
		getRoomUpgrade,
		getBoardUpgrade,
		getFlightUpgrade,
		getTransferUpgrade,
		calculateActivitiesUpgradePrice,
		getLuggageTotalPrice,
	],
	(
		quotationItems = [],
		roomUpgrade = {},
		boardUpgrade = {},
		flightUpgrade = {},
		transferUpgrade = {},
		activitiesUpgradePrice = 0,
		luggageTotalPrice = 0
	) => {
		const prices = quotationItems.map(quotationItem => {
			return quotationItem.price;
		});

		prices.push(boardUpgrade.upgradePrice || 0);
		prices.push(flightUpgrade.upgradePrice || 0);
		prices.push(roomUpgrade.upgradePrice || 0);
		prices.push(activitiesUpgradePrice);
		prices.push(transferUpgrade.upgradePrice || 0);
		prices.push(luggageTotalPrice);

		const subtotal = prices.reduce((previousPrice, price) => {
			return previousPrice + price;
		});
		return subtotal;
	}
);

export const calculateFlexExtra = createSelector(
	[calculateQuoteBaseAndExtrasTotal, getInsuranceFlex, getPassengers],
	(total = 0, insuranceFlex = undefined, passengers = []) => {
		const nbPassengers = passengers.length;
		const flex = calculateFlexIncluded(insuranceFlex, total, nbPassengers);
		return flex;
	}
);

/**
 * Calcule le total du panier de le page quote en tenant compte de :
 * - supplement chambre
 * - supplement pension
 * - supplement vol
 * - supplement transfert
 * - supplement activites
 * - supplement bagages
 * - supplement flex
 * C'est le total envoyé à la requete de prebook
 */
export const calculatePreBookTotal = createSelector(
	[calculateQuoteBaseAndExtrasTotal, calculateFlexExtra],
	(total = 0, flexExtra = 0) => {
		return total + flexExtra;
	}
);

export const calculateBasePrice = createSelector(
	[getQuotationItems],
	(quotationItems = []) => {
		let total = 0;

		quotationItems.forEach(quotationItem => {
			total += quotationItem.price;
		});

		return total;
	}
);

/**
 * Calcule le total du panier en tenant compte de :
 * - supplement chambre
 * - supplement pension
 * - supplement vol
 * - supplement transfert
 * - supplement activites
 * - supplement bagages
 * - reduction code promo
 * - reduction credits
 * - reduction credits
 * - supplement assurances [new]
 * Ce total s'entend hors frais de financement et est utilisé pour calculer les coûts de crédits [new]
 */
export const getQuoteTotal = createSelector(
	[calculatePreBookTotal, getInsurance, getAllCouponsTotal],
	(preBookTotal = 0, insurance = {}, allCouponsAmount = 0) => {
		const total = preBookTotal + (insurance.price || 0) - allCouponsAmount;
		return total > 0 ? total : 0;
	}
);

/**
 * Calcule le total du panier en tenant compte de :
 * - supplement chambre
 * - supplement pension
 * - supplement vol
 * - supplement transfert
 * - supplement activites
 * - supplement bagages
 * - reduction code promo
 * - reduction credits
 * - reduction avoirs
 * - supplement assurances
 * - éventuel écart de prix entre quote et paiement [new]
 * Ce total s'entend hors frais de financement et est utilisé pour calculer les coûts de crédits [new]
 */
export const getQuoteTotalWithDelta = createSelector(
	[calculatePreBookTotal, getInsurance, getAllCouponsTotal, getDeltaBasePrice],
	(preBookTotal = 0, insurance = {}, allCouponsAmount = 0, deltaBasePrice = 0) => {
		const total = preBookTotal + (insurance.price || 0) + deltaBasePrice - allCouponsAmount;
		return total > 0 ? total : 0;
	}
);

const getCallCenter = state => state.callCenter;

export const getContextCallCenter = ({ isPaymentPage, isQuotationPage, isConfirmationPage }) =>
	createSelector(
		[getQuoteTotal, getQuoteTotalWithDelta, getCallCenter],
		(totalAvecFrais, totalAvecFraisEtDelta, callCenter) => {
			const showContextCallCenter = isPaymentPage || isQuotationPage || isConfirmationPage;
			const total = isPaymentPage ? totalAvecFraisEtDelta : totalAvecFrais;

			const contextCallCenter = showContextCallCenter
				? (get(callCenter, "contextPhones") &&
						get(callCenter, "contextPhones").find(context => {
							let contextFound = true;
							if (context.minPrice && total < context.minPrice) {
								contextFound = false;
							}
							if (context.maxPrice && total > context.maxPrice) {
								contextFound = false;
							}
							return contextFound;
						})) ||
				  {}
				: get(callCenter, "contextPhones[0]") || {};
			// eslint-disable-next-line no-unused-vars
			const { contextPhones, ...mixedCallCenter } = {
				...callCenter,
				...contextCallCenter,
			};
			return mixedCallCenter;
		}
	);

export const getMaxLuggageCount = createSelector(
	[getAdults, getChildren],
	(adultsCount = 0, childrenCount) => {
		return adultsCount + childrenCount;
	}
);

export const getTravellersTotalCount = createSelector(
	[getAdults, getChildren, getBabies],
	(adultsCount = 0, childrenCount = 0, babyCount = 0) => {
		return adultsCount + childrenCount + babyCount;
	}
);

export const getMaxLuggageCountForOccupancies = createSelector(
	[getOccupancies],
	(occupancies = []) => {
		let total = 0;
		occupancies.forEach(occupancy => {
			total += occupancy.adults + occupancy.children;
		});
		return total;
	}
);

export const getSelectedTransportType = createSelector(
	[getFlight],
	(flight = {}) => {
		return flight.type;
	}
);
